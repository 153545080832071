import { IUseCase } from '@/core/IUseCase'
import { IOrderedListRepository } from './repositories/IOrderedListRepository'


export class DeleteOrderedList implements IUseCase<string, Promise<void>> {

	constructor(private readonly orderedListRepository: IOrderedListRepository) {}

	public async execute(id: string): Promise<void> {
		await this.orderedListRepository.deleteById(id)
	}
}