






























import { computed, defineComponent, onBeforeMount, toRef, toRefs, watch } from '@vue/composition-api'
import { LatLngBounds, LatLngLiteral } from 'leaflet'
import { MapSettings } from '../MapSettings'
import { useStore } from '@/composables/useStore'
import { useMapState } from '../composables/useMapState'
import ContentLayout from '../layout/ContentLayout.vue'
import MainMap from '../presenters/MainMap.vue'

export default defineComponent({
	components: { ContentLayout, MainMap },
	props: {
		group: { type: String, required: true },
		id: { type: String },
	},
	setup(props) {
		const store = useStore()

		// map
		const { zoom, center, settings } = useMapState()
		const updateMapZoom = (value: number) => { zoom.value = value }
		const updateMapCenter = (value: LatLngLiteral) => { center.value = value }
		const updateMapSettings = (value: MapSettings) => { settings.value = value }

		// filters
		const isBoundsFiltering = computed(() => store.state.filters.bounds.enabled)
		const isBoundsFilterDisabled = computed(() => store.getters.propertyListType === 'list')
		const updateBoundsFiltering = (value: boolean) => { store.state.filters.bounds.enabled = value }
		const updateBoundsFilter = (value: LatLngBounds) => { store.state.filters.bounds.value = value }

		const selection = computed(() => store.getters.property)
		const properties = computed(() => store.getters.filteredItemsExceptMapBounds)
		const searchCount = computed(() => store.getters.filteredItems.length)

		// data from props
		const { group } = toRefs(props)
		const id = toRef(props, 'id')
		const updateSelectedGroup = () => store.dispatch('selectPropertyGroup', group.value)
		const updateSelectedID = () => store.dispatch('selectProperty', id.value || null)
		watch(group, updateSelectedGroup)
		watch(id, updateSelectedID)

		onBeforeMount(() => {
			store.dispatch('fetchAll')
			updateSelectedGroup()
			updateSelectedID()
		})

		return {
			zoom,
			center,
			settings,
			selection,
			properties,
			searchCount,
			isBoundsFiltering,
			isBoundsFilterDisabled,
			updateMapZoom,
			updateMapCenter,
			updateMapSettings,
			updateBoundsFilter,
			updateBoundsFiltering,
		}
	},
})
