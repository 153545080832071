<template>
<v-card class="d-flex flex-column">
	<v-btn small icon tile :ripple="false" color="primary" :disabled="!canZoomIn" @click="zoomIn">
		<v-icon small>mdi-plus-thick</v-icon>
	</v-btn>
	<v-divider />
	<v-btn small icon tile :ripple="false" color="primary" :disabled="!canZoomOut" @click="zoomOut">
		<v-icon small>mdi-minus-thick</v-icon>
	</v-btn>
</v-card>
</template>


<script>
export default {
	props: {
		value: { type: Number, default: 0 },
		min: { type: Number, default: 0 },
		max: { type: Number, default: null },
	},
	computed: {
		canZoomIn() {
			return this.max === null || this.value < this.max
		},
		canZoomOut() {
			return this.value > this.min
		},
	},
	methods: {
		zoomIn() {
			this.$emit('input', this.value + 1)
		},
		zoomOut() {
			this.$emit('input', this.value - 1)
		},
	},
}
</script>
