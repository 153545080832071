import { IUseCase } from '@/core/IUseCase'
import { IPropertyRepository } from './repositories/IPropertyRepository'


interface UpdatePropertyTagsParams {
	_id: string
	tags: string[]
}

export class UpdatePropertyTags implements IUseCase<UpdatePropertyTagsParams, Promise<void>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute({ _id, tags }: UpdatePropertyTagsParams): Promise<void> {
		await this.propertyRepository.updateTagsById(_id, tags)
	}
}