import { IUseCase } from '@/core/IUseCase'
import { IPropertyRepository } from './repositories/IPropertyRepository'


interface UpdatePropertyRatingParams {
	_id: string
	rating: number
}

export class UpdatePropertyRating implements IUseCase<UpdatePropertyRatingParams, Promise<void>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute({ _id, rating }: UpdatePropertyRatingParams): Promise<void> {
		await this.propertyRepository.updateRatingById(_id, rating)
	}
}