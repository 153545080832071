


















import { computed, defineComponent, toRef, toRefs } from '@vue/composition-api'
import { LLayerGroup, LCircle, LCircleMarker } from 'vue2-leaflet'

export default defineComponent({
	components: { LLayerGroup, LCircle, LCircleMarker },
	props: {
		position: { type: GeolocationPosition }
	},
	setup(props) {
		const position = toRef(props, 'position')

		const center = computed(() => [position.value?.coords.latitude, position.value?.coords.longitude])
		const accuracy = computed(() => position.value?.coords.accuracy)

		return { center, accuracy }
	}
})
