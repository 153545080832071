import { IPropertyRepository } from '@/domain/repositories/IPropertyRepository'
import { Property } from '@/domain/entities/Property'
import { PropertyHttpDataSource } from './data-sources/PropertyHttpDataSource'
import { PropertyMapper } from './PropertyMapper'
import { Coords } from '@/domain/entities/value-objects/Coords'


export class PropertyRepository implements IPropertyRepository {

	constructor(
		private readonly propertyHttpDataSource: PropertyHttpDataSource,
		private readonly propertyMapper: PropertyMapper,
	) {}

	
	async findAll(): Promise <Property[]> {
		const dtos = await this.propertyHttpDataSource.fetchAll()
		return this.propertyMapper.toEntities(dtos)
	}

	async findInfoByURL(url: string): Promise<Property | null> {
		const dto = await this.propertyHttpDataSource.fetchInfo(url)
		return dto && this.propertyMapper.toEntity(dto)
	}

	async create(property: Partial<Property>): Promise<Property> {
		const dto = await this.propertyHttpDataSource.create(property)
		return this.propertyMapper.toEntity(dto)
	}

	async updateTagsById(_id: string, tags: string[]): Promise<void> {
		await this.propertyHttpDataSource.update({ _id, tags })
	}
	
	async updateNotesById(_id: string, notes: string): Promise<void> {
		await this.propertyHttpDataSource.update({ _id, notes })
	}
	
	async updateRatingById(_id: string, rating: number): Promise<void> {
		await this.propertyHttpDataSource.update({ _id, rating })
	}
	
	async updatePositionById(_id: string, actualPosition: Coords): Promise<void> {
		await this.propertyHttpDataSource.update({ _id, actualPosition })
	}
	
	async updateVisibilityById(_id: string, hidden: boolean): Promise<void> {
		await this.propertyHttpDataSource.update({ _id, hidden })
	}

	async refreshById(_id: string): Promise<Property> {
		const dto = await this.propertyHttpDataSource.refreshById(_id)
		return this.propertyMapper.toEntity(dto)
	}

	async deleteById(_id: string): Promise<void> {
		await this.propertyHttpDataSource.deleteById(_id)
	}
}