<template>
<div class="fill-height card-bg appBar">
	<v-card width="300" class="card">
		<v-card-text>
			<v-form @submit.prevent>
				<span class="subtitle-2">Nom d'utilisateur</span>
				<v-text-field single-line :error="!!error" outlined dense v-model="username" autofocus />
				
				<span class="subtitle-2">Mot de passe</span>
				<v-text-field single-line :error="!!error" outlined dense v-model="password" type="password" autocomplete="current-password" />

				<v-btn type="submit" block color="primary" @click="submit">Connexion</v-btn>
			</v-form>
		</v-card-text>
	</v-card>
</div>
</template>


<script>
import { useStore } from '@/composables/useStore'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
	setup() {
		const store = useStore()

		const username = ref(null)
		const password = ref(null)
		const error = computed(() => store.state.loginError)

		const submit = () => {
			store.dispatch('login', {
				username: username.value,
				password: password.value,
			})
		}

		return { username, password, error, submit }
	},
})
</script>


<style lang="less" scoped>
.card-bg { padding-top: 120px }
.card { margin: 0 auto }
</style>