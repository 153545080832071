export class GeolocationNativeDataSource {

	isSupported() {
		return 'geolocation' in navigator
	}

	async getCurrentPosition(): Promise<GeolocationPosition> {
		if (!this.isSupported) throw GeolocationPositionError.POSITION_UNAVAILABLE

		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject)
		})
	}
}