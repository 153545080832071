export default {
	computed: {
		canShare: () => 'share' in navigator,
	},
	methods: {
		async share(title, text, url = window.location.href) {
			if (!this.canShare) { return console.log('ShareAPI is not supported') }

			return navigator.share({ title, text, url })
		},
	},
}