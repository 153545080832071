import { IUseCase } from '@/core/IUseCase'
import { Property } from './entities/Property'
import { IPropertyRepository } from './repositories/IPropertyRepository'


type CreatePropertyParams = Partial<Property>

export class CreateProperty implements IUseCase<CreatePropertyParams, Promise<Property>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute(property: CreatePropertyParams): Promise<Property> {
		return this.propertyRepository.create(property)
	}
}