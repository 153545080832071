'use strict';

import { SorterType } from './types';


const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

//=================================================================================================================

export const unique = (item, i, array) => array.indexOf(item) === i;

//=================================================================================================================

export const exists = Boolean;

//=================================================================================================================

export const hidden = item => item.hidden;
export const visible = item => !item.hidden;

//=================================================================================================================

export const sortAZ = fieldName => (a, b) => {
	if (a[fieldName] < b[fieldName]) { return -1 }
	if (a[fieldName] > b[fieldName]) { return +1 }
	return 0
};

//=================================================================================================================

export const fullTextSearch = query => {
	const q = query && query.trim();
	if (!q) { return null }

	return item => q.split(' ').some(token => {
		const regex = new RegExp(escapeRegExp(token), 'i');
		const keys = ['cityName','departmentName','regionName','zip','app','title','description','price','landSurface','buildingSurface','buildableSurface'];
		return keys.some(key => !!item[key] && regex.test(String(item[key])));
	});
};

//=================================================================================================================

export const sorterValueExtractor = sorterType => {
	switch(sorterType) {
		// surfaces
		case SorterType.BuildingSurface:  return item => item['buildingSurface'];
		case SorterType.BuildableSurface: return item => item['buildableSurface'];
		case SorterType.LandSurface:      return item => item['landSurface'];

		// prices
		case SorterType.Price:                   return item => item['price'];
		case SorterType.PricePerLandSqMeter:     return item => item['landSurface']      ? item['price'] / item['landSurface']      : null;
		case SorterType.PricePerBuildingSqMeter: return item => item['buildableSurface'] ? item['price'] / item['buildableSurface'] : null;

		// metadata
		case SorterType.CreationDate: return item => item['createdAt'];
		case SorterType.EditionDate:  return item => item['updatedAt'];
		
		// location
		case SorterType.City:       return item => item['cityName'];
		case SorterType.Department: return item => item['zip'];
		case SorterType.Region:     return item => item['regionName'];
		case SorterType.Population: return item => item['population'];
		

		default: throw new Error(`Unsupported sorter key ${sorterType.key}`);
	}
};