import { Cookie } from '@/domain/entities/Cookie';
import { ICookieRepository } from '@/domain/repositories/ICookieRepository'
import { ExportCookieHttpDataSource } from './data-sources/ExportCookieHttpDataSource'


export class CookieRepository implements ICookieRepository {

	constructor(private readonly exportCookieHttpDataSource: ExportCookieHttpDataSource) {}


	async exportCookie(cookie: Cookie): Promise<void> {
		await this.exportCookieHttpDataSource.exportCookie(cookie)
	}
}