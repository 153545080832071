export const ThunderForestTrain = {
	name: 'Transport',
	label: 'Voies ferrées',
	icon: 'mdi-train',
	type: 'base',
	visible: false,
	url: 'https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey={apikey}',
	options: {
		apikey: 'e9314d4d61b944eab48f3cb279cf4767',
	},
}