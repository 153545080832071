<template>
<v-dialog v-model="visible" persistent width="400" scrollable style="z-index:99999" :fullscreen="$vuetify.breakpoint.xs">
	<v-card>
		<v-card-title class="primary white--text">
			Nouvelle liste
		</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
				<v-container fluid>
					<v-row justify="center">
						<v-col cols="12" class="mt-4">
							<v-text-field
								outlined
								dense
								autofocus
								autocomplete="off"
								label="Nom"
								v-model="draft.name"
								:rules="[rules.required, rules.unique]" />
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-card-text>
		<v-divider />
		<v-card-actions>
			<v-spacer />
			<v-btn text @click="closeNewListDialog">Annuler</v-btn>
			<v-btn color="primary" text @click="save">Enregistrer</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
	data() { return {
		valid: true,
		rules: {
			required: value => { return !!value || 'obligatoire' },
			unique: value => { return !this.lists.includes(value) || 'existe déjà' }
		},
	}},
	computed: {
		...mapState({
			visible: state => state.newList.visible,
			draft: state => state.newList.draft,
		}),
		...mapGetters(['lists']),
	},
	methods: {
		...mapActions(['closeNewListDialog','createList']),

		// form & dialog
		resetValidation() {
			this.$refs.form && this.$refs.form.resetValidation();
		},
		async save() {
			const isValid = this.$refs.form.validate(true);
			if (!isValid) { return }
			
			await this.createList(this.draft.name);
			this.closeNewListDialog();
		},
	},
	watch: {
		visible() {
			// reset dialog state when opening or closing
			this.resetValidation();
		},
	},
}
</script>