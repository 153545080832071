'use strict';

import numeral from 'numeral';
import moment from 'moment';


export const formatNumber = value => numeral(value).format('0,0');

//=================================================================================================================

export const formatDate = value => moment(value).format('LL');

//=================================================================================================================

export const itemIcon = item => item.type === 'Land' ? 'mdi-tree' : 'mdi-home';

//=================================================================================================================

export const landPricePerSqMeter = item => {
	if (!item.landSurface) { return '-' }
	const value = Math.round(item.price / item.landSurface);
	return formatNumber(value);
};

//=================================================================================================================

export const buildingPricePerSqMeter = item => {
	if (!item.buildingSurface) { return '-' }
	const value = Math.round(item.price / item.buildingSurface);
	return formatNumber(value);
};
