<template>
<v-dialog fullscreen scrollable style="z-index:99999" v-model="gallery.visible" @keydown.left="prev" @keydown.right="next">
	<v-card tile flat class="fill-height d-flex flex-column align-stretch" v-if="property">
		<v-toolbar dense flat class="flex-grow-0">
			<span class="title">{{ property.title }}</span>
			<v-spacer />
			<v-btn icon @click="closeGalleryDialog">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<div class="d-flex flex-column flex-grow-1 align-stretch">
			<div class="flex-grow-1 d-flex justify-center align-center" style="flex-basis: 0; overflow: hidden">
				<v-carousel
					ref="carousel"
					height="100%"
					hide-delimiters
					show-arrows-on-hover
					:show-arrows="!$vuetify.breakpoint.mobile"
					v-model="gallery.index"
					@keydown.left="debug">
					
					<v-carousel-item v-for="(url, i) in property.images" :key="i">
						<div class="fill-height d-flex justify-center align-center">
							<v-img max-width="100%" max-height="100%" contain :src="url" />
						</div>
					</v-carousel-item>
				</v-carousel>
			</div>
			<v-slide-group
				class="pa-4 flex-shrink-1 flex-grow-0"
				active-class="active-img"
				show-arrows="always"
				mandatory
				center-active
				v-model="gallery.index">

				<v-slide-item v-for="(url, i) in property.images" :key="i" v-slot="{ toggle }">
					<v-img class="thumbnail" max-width="150" height="72" contain :src="url" @click="toggle" />
				</v-slide-item>
			</v-slide-group>
		</div>
	</v-card>
</v-dialog>
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
	computed: {
		...mapState({
			gallery: state => state.gallery,
		}),
		...mapGetters(['property']),
	},
	methods: {
		...mapActions(['closeGalleryDialog']),
		prev() { this.$refs['carousel'].prev() },
		next() { this.$refs['carousel'].next() },
	},
}
</script>


<style lang="less" scoped>
.thumbnail {
	margin: 0 4px;
	opacity: 0.5;

	&.active-img { opacity: 1 }
}
</style>