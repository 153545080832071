import Vue from 'vue'
import VueRouter, { RawLocation } from 'vue-router'
import Home from '@/application/views/VHome.vue'
import MyList from '@/application/fragments/MyList.vue'
import MyOverview from '@/application/fragments/MyOverview.vue'

class Router extends VueRouter {
	navback(location: RawLocation) {
		if (window.history.state) { this.back() }
		else { this.push(location) }
	}
}

Vue.use(Router);

export const router = new Router({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{ path: '/', redirect: '/all' },
		{
			path: '/:group',
			component: Home,
			props: true,
			children: [
				{
					path: '',
					name: 'home',
					component: MyList,
					props: true,
				},
				{
					path: ':id',
					name: 'overview',
					component: MyOverview,
					props: true,
				},
			],
		},

		// {
		// 	path: '/:list?',
		// 	name: 'home',
		// 	component: Home,
		// 	props: true,
		// 	children: [
		// 		{
		// 			path: '',
		// 			component: MyList,
		// 			props: true,
		// 		},
		// 		{
		// 			path: ':id',
		// 			name: 'overview',
		// 			component: MyOverview,
		// 			props: true,
		// 		}
		// 	],
		// },
		// {
		// 	path: '/:id',
		// 	name: 'details',
		// 	component: Details,
		// 	props: true,
		// 	// route level code-splitting
		// 	// this generates a separate chunk (about.[hash].js) for this route
		// 	// which is lazy-loaded when the route is visited.
		// 	//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
		// }
	],
})
