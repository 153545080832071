import { Credentials } from '@/domain/entities/value-objects/Credentials'
import { HttpClient } from '../services/HttpClient'
import { UserSessionHttpDTO } from './UserSessionHttpDTO'


export type LoginParams = Credentials | { token: string }

export class UserSessionHttpDataSource {

	constructor(private readonly httpClient: HttpClient) {}
	
	async login(params: LoginParams): Promise<UserSessionHttpDTO> {
		return this.httpClient.fetch<UserSessionHttpDTO>('/login', {
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(params),
		})
	}
}