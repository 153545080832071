import { IUserRepository } from '@/domain/repositories/IUserRepository'
import { User } from '@/domain/entities/User'
import { Credentials } from '@/domain/entities/value-objects/Credentials'
import { UnauthorizedError } from '@/domain/errors/UnauthorizedError'
import { TokenLocalDataSource } from './data-sources/TokenLocalDataSource'
import { LoginParams, UserSessionHttpDataSource } from './data-sources/UserSessionHttpDataSource'
import { HttpClient } from './services/HttpClient'


export class UserRepository implements IUserRepository {

	constructor(
		private readonly tokenLocalDataSource: TokenLocalDataSource,
		private readonly userSessionHttpDataSource: UserSessionHttpDataSource,
		private readonly httpClient: HttpClient,
	) {}


	async autologin(): Promise<User> {
		const token = this.tokenLocalDataSource.getToken()
		if (!token) { throw new UnauthorizedError() }
		
		return this.loginAndUpdateToken({ token })
	}
	
	async login(credentials: Credentials): Promise<User> {
		return this.loginAndUpdateToken(credentials)
	}

	logout(): void {
		this.tokenLocalDataSource.clearToken()
		this.httpClient.setAuthorization(null)
	}

	//=================================================================================================================

	private async loginAndUpdateToken(params: LoginParams): Promise<User> {
		try {
			const { user, token } = await this.userSessionHttpDataSource.login(params)
			
			this.tokenLocalDataSource.setToken(token)
			this.httpClient.setAuthorization(token)
			
			return user
		}
		catch(err) {
			this.tokenLocalDataSource.clearToken()
			this.httpClient.setAuthorization(null)

			throw new UnauthorizedError()
		}
	}
}