import { HttpClient } from '../services/HttpClient'
import { ExportCookieHttpDTO } from './ExportCookieHttpDTO'


export class ExportCookieHttpDataSource {

	constructor(private readonly httpClient: HttpClient) {}


	async exportCookie(cookie: ExportCookieHttpDTO): Promise<void> {
		await this.httpClient.fetch<void>('/api/import/cookie', {
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(cookie),
		})
	}
}