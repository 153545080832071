<template>
<div class="d-flex align-center">
	<span class="me-1 caption hidden-sm-and-down">{{ index + 1 }} sur {{ total }}</span>
	<v-btn icon :disabled="!hasPrev" @click="prev">
		<v-icon>mdi-chevron-left</v-icon>
	</v-btn>
	<v-btn icon :disabled="!hasNext" @click="next">
		<v-icon>mdi-chevron-right</v-icon>
	</v-btn>
</div>
</template>


<script>
export default {
	props: {
		index: { type: Number, default: -1 },
		total: { type: Number, default: 0 },
	},
	computed: {
		hasPrev() { return this.index > 0 },
		hasNext() { return this.index < this.total - 1 },
	},
	methods: {
		prev() { this.$emit('prev') },
		next() { this.$emit('next') },
	},
}
</script>
