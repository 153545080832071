




























import { computed, defineComponent, onMounted } from '@vue/composition-api'
import { provideTheme, useTheme } from '@/composables/useTheme'
import { provideStore, useStore } from '@/composables/useStore'
import { provideMainLayout, useMainLayout } from '@/application/composables/useMainLayout'
import { useAuth } from '@/composables/useAuth'
import AppUpdateController from '@/application/components/AppUpdateController.vue'
import CreationDialog from '@/application/dialogs/CreationDialog.vue'
import CookieDialog from '@/application/dialogs/CookieDialog.vue'
import NewListDialog from '@/application/dialogs/NewListDialog.vue'
import GalleryDialog from '@/application/dialogs/GalleryDialog.vue'
import LocationDialog from '@/application/dialogs/LocationDialog.vue'
import Login from '@/application/views/Login.vue'
import MyFilter from '@/application/fragments/MyFilter.vue'
import MyMenu from '@/application/fragments/MyMenu.vue'
import AddMenuButton from '@/application/components/AddMenuButton.vue'

export default defineComponent({
	components: {
		Login, 
		MyFilter,
		MyMenu,
		AddMenuButton,
		AppUpdateController,
		CreationDialog,
		CookieDialog,
		NewListDialog,
		GalleryDialog,
		LocationDialog,
	},
	setup(props, context) {
		provideStore(context.root.$store)
		provideTheme(context.root.$vuetify)
		provideMainLayout()

		const store = useStore()
		const { vuetify, syncColorScheme } = useTheme()
		const { logged, autologin } = useAuth()
		const {
			isFixedViewVisible,
			isScrollViewVisible,
			showFixedView,
			showScrollView,
		} = useMainLayout()

		const isMobile = computed(() => vuetify.breakpoint.smAndDown)
		const isMenuVisible = computed(() => store.state.isMenuVisible)

		const toggleMenu = (isVisible: boolean) => store.dispatch('toggleMenu', isVisible)
		const updateUIBreakpoints = () => store.dispatch('updateUIBreakpoints', vuetify.breakpoint)
		const onResize = () => {
			// JS fix for vh bug (see https://css-tricks.com/the-trick-to-viewport-units-on-mobile)
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}

		onMounted(() => {
			syncColorScheme()
			updateUIBreakpoints()
			autologin()
		})

		return {
			logged,
			isMobile,
			isMenuVisible,
			isFixedViewVisible,
			isScrollViewVisible,
			showFixedView,
			showScrollView,
			toggleMenu,
			onResize,
		}
	},
})
