


































import { computed, defineComponent, onMounted, PropType, ref, toRef, toRefs, watch } from '@vue/composition-api'
import { LMarker, LPopup } from 'vue2-leaflet'
import { LatLngLiteral } from 'leaflet'
import { MapSettings } from '../MapSettings'
import { Property } from '@/domain/entities/Property'
import MapBase from '@/presenters/MapBase.vue'
import MapControlCheckbox from '@/presenters/MapControlCheckbox.vue'
import PropertyQuickOverview from './PropertyQuickOverview.vue'

export default defineComponent({
	components: { MapBase, MapControlCheckbox, LMarker, LPopup, PropertyQuickOverview },
	props: {
		zoom: { type: Number, required: true },
		center: { type: Object as PropType<LatLngLiteral>, required: true },
		settings: { type: Object as PropType<MapSettings>, required: true },
		boundsFiltering: { type: Boolean, required: true },
		boundsFilterDisabled: { type: Boolean, default: false },
		items: { type: Array as PropType<Property[]>, required: true },
		selection: { type: Object as PropType<Property> },
	},
	setup(props, { refs }) {
		const { items } = toRefs(props)
		const selection = toRef(props, 'selection')

		const isMapReady = ref(false)
		const setMapReady = () => { isMapReady.value = true }

		const markers = computed(() => items.value.map(item => {
			const { lat, lng } = item.actualPosition || item
			return {
				...item,
				markerPosition: { lat, lng }
			}
		}))

		const syncMarkerPopup = (requestedProperty?: Property | null) => {
			if (!isMapReady.value || !requestedProperty) { return }

			const [marker] = refs[requestedProperty._id] as LMarker[] || []
			const shouldOpenPopup = requestedProperty._id === (selection.value && selection.value._id)
			const isPopupOpen = marker.mapObject.isPopupOpen()

			if (shouldOpenPopup === isPopupOpen) { return }
			marker.mapObject[shouldOpenPopup ? 'openPopup' : 'closePopup']()
		}
		
		watch(selection, (newValue, oldValue) => {
			if (newValue === oldValue) { return }
			syncMarkerPopup(oldValue)
			syncMarkerPopup(newValue)
		})

		onMounted(() => {
			syncMarkerPopup(selection.value)
		})

		return {
			markers,
			syncMarkerPopup,
			setMapReady,
		}
	}
})
