import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from '@/application/store'
import '@/plugins/vue-composition-api'
import vuetify from '@/plugins/vuetify'
import '@/plugins/vue-resize'
import '@/plugins/fonts'
import '@/plugins/leaflet'
import '@/plugins/numeral'
import '@/plugins/moment'
import '@/plugins/portal-vue'
import './registerServiceWorker'

Vue.config.productionTip = false;


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app');
