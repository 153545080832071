import { Property } from '@/domain/entities/Property'
import { PropertyHttpDTO } from './data-sources/PropertyHttpDTO'

export class PropertyMapper {
	
	toEntities(dtos: PropertyHttpDTO[]): Property[] {
		return dtos.map(dto => this.toEntity(dto))
	}

	toEntity(dto: PropertyHttpDTO): Property {
		return {
			_id: dto._id,
			city: dto.city,
			cityName: dto.cityName,
			department: dto.department,
			departmentName: dto.departmentName,
			region: dto.region,
			regionName: dto.regionName,
			zip: dto.zip,
			lat: dto.lat,
			lng: dto.lng,
			actualPosition: dto.actualPosition,
			population: dto.population,
			app: dto.app,
			appId: dto.appId,
			appRef: dto.appRef,
			images: dto.images,
			type: dto.type,
			title: dto.title,
			description: dto.description,
			price: dto.price,
			landSurface: dto.landSurface,
			buildingSurface: dto.buildingSurface,
			buildableSurface: dto.buildableSurface,
			importedAt: dto.importedAt ? new Date(dto.importedAt) : undefined,
			createdAt: dto.createdAt ? new Date(dto.createdAt) : null,
			updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : null,
			hidden: dto.hidden,
			notes: dto.notes,
			rating: dto.rating,
			tags: dto.tags,
		}
	}
}