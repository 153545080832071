import { Property } from '@/domain/entities/Property'
import { HttpClient } from '../services/HttpClient'
import { PropertyHttpDTO } from './PropertyHttpDTO'


export class PropertyHttpDataSource {

	constructor(private readonly httpClient: HttpClient) {}


	async fetchAll(): Promise<PropertyHttpDTO[]> {
		return this.httpClient.fetch<PropertyHttpDTO[]>('/api/properties')
	}
	
	async fetchInfo(url: string): Promise<PropertyHttpDTO | null> {
		const encodedURL = encodeURIComponent(url)
		return this.httpClient.fetch<PropertyHttpDTO | null>(`/api/import?url=${encodedURL}`)
	}
	
	async create(property: Partial<Property>): Promise<PropertyHttpDTO> {
		return this.httpClient.fetch<PropertyHttpDTO>(`/api/properties`, {
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(property),
		})
	}
	
	async update({ _id, ...data }: Partial<Property>): Promise<void> {
		return this.httpClient.fetch<void>(`/api/properties/${_id}`, {
			method: 'put',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(data),
		})
	}
	
	async refreshById(_id: string): Promise<PropertyHttpDTO> {
		return this.httpClient.fetch<PropertyHttpDTO>(`/api/properties/${_id}/refresh`, { method: 'post' })
	}
	
	async deleteById(_id: string): Promise<void> {
		return this.httpClient.fetch<void>(`/api/properties/${_id}`, { method: 'delete' })
	}
}