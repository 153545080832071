import { IUseCase } from '@/core/IUseCase'
import { Coords } from './entities/value-objects/Coords'
import { IPropertyRepository } from './repositories/IPropertyRepository'


interface UpdatePropertyPositionParams {
	_id: string
	actualPosition: Coords
}

export class UpdatePropertyPosition implements IUseCase<UpdatePropertyPositionParams, Promise<void>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute({ _id, actualPosition }: UpdatePropertyPositionParams): Promise<void> {
		await this.propertyRepository.updatePositionById(_id, actualPosition)
	}
}