<template>
<v-snackbar
	color="primary"
	top
	:right="!isMobile"
	:centered="isMobile"
	:transition="isMobile ? 'slide-y-transition' : 'slide-x-reverse-transition'"
	:timeout="-1"
	:value="hasUpdate">

    Mise à jour disponible

	<template v-slot:action="{ attrs }">
    	<v-btn text v-bind="attrs" @click="refreshApp">Relancer</v-btn>
	</template>
</v-snackbar>
</template>


<script>
import updatable from '@/application/mixins/updatable';

export default {
    mixins: [updatable],
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.mobile;
		},
	}
}
</script>
