import { IUseCase } from '@/core/IUseCase'
import { Property } from './entities/Property'
import { IPropertyRepository } from './repositories/IPropertyRepository'


export class GetPropertyInfo implements IUseCase<string, Promise<Property | null>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute(url: string): Promise<Property | null> {
		return this.propertyRepository.findInfoByURL(url)
	}
}