<template>
<v-dialog fullscreen scrollable style="z-index:99999" hide-overlay transition="dialog-bottom-transition" v-model="isDialogVisible">
	<v-card flat tile>
		<drilldown-map v-if="isMapVisible"
			:position="propertyPosition"
			@update:position="savePosition"
			@close="closeDialog" />
	</v-card>
</v-dialog>
</template>


<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import DrilldownMap from '@/application/components/DrilldownMap'

export default defineComponent({
	components: { DrilldownMap },
	setup() {
		const store = useStore()

		const isMapVisible = ref(false)
		const isDialogVisible = computed({
			get() {
				return store.state.location.visible
			},
			set(value) {
				store.dispatch(value ? 'openLocationDialog' : 'closeLocationDialog')
			}
		})

		const closeDialog = () => { isDialogVisible.value = false }

		const propertyPosition = computed(() => {
			const property = store.getters.property
			if (!property) { return }
			
			const { lat, lng } = property.actualPosition || property
			return { lat, lng } 
		})

		const savePosition = async (newPosition) => {
			try {
				const propertyID = store.getters.propertyID
				await store.dispatch('updatePropertyPosition', { propertyID, ...newPosition })
			}
			catch(err) {
				store.dispatch('warn', `Erreur lors de la mise à jour de la position : ${err.message}`)
			}
		}

		watch(isDialogVisible, (isDialogBeingVisible) => {
			// wait for the end of the transition to display the map
			setTimeout(() => {
				isMapVisible.value = isDialogBeingVisible
			}, 300);
		})

		return {
			isDialogVisible,
			isMapVisible,
			propertyPosition,
			savePosition,
			closeDialog,
		}
	},
})
</script>
