import { IUseCase } from '@/core/IUseCase'
import { IPropertyRepository } from './repositories/IPropertyRepository'


interface UpdatePropertyVisibilityParams {
	_id: string
	hidden: boolean
}

export class UpdatePropertyVisibility implements IUseCase<UpdatePropertyVisibilityParams, Promise<void>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute({ _id, hidden }: UpdatePropertyVisibilityParams): Promise<void> {
		await this.propertyRepository.updateVisibilityById(_id, hidden)
	}
}