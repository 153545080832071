export const GeoportailIGN = {
	name: 'Geoportail.GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN25TOUR.CV',
	label: 'IGN',
	icon: 'mdi-ski-cross-country',
	type: 'base',
	visible: false,
	url: 'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={layer}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
	options: {
		layer: 'GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN25TOUR.CV',
		apikey: 'an7nvfzojv5wa96dsga5nk8w',
		format: 'image/jpeg',
		style: 'normal',
		bounds: [[-75, -180], [81, 180]],
		minZoom: 2,
		maxZoom: 19,
	},
}