import { IUseCase } from '@/core/IUseCase'
import { IPropertyRepository } from './repositories/IPropertyRepository'


interface UpdatePropertyNotesParams {
	_id: string
	notes: string
}

export class UpdatePropertyNotes implements IUseCase<UpdatePropertyNotesParams, Promise<void>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute({ _id, notes }: UpdatePropertyNotesParams): Promise<void> {
		await this.propertyRepository.updateNotesById(_id, notes)
	}
}