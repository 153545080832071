import { IOrderedListRepository } from '@/domain/repositories/IOrderedListRepository'
import { OrderedList } from '@/domain/entities/OrderedList'
import { OrderedListHttpDataSource } from './data-sources/OrderedListHttpDataSource'


export class OrderedListRepository implements IOrderedListRepository {

	constructor(private readonly orderedListHttpDataSource: OrderedListHttpDataSource) {}

	
	async findAll(): Promise <OrderedList[]> {
		return this.orderedListHttpDataSource.fetchAll()
	}

	async create(list: OrderedList): Promise<OrderedList> {
		return this.orderedListHttpDataSource.create(list)
	}

	async updateItemsById(_id: string, items: string[]): Promise<void> {
		await this.orderedListHttpDataSource.update({ _id, items })
	}

	async deleteById(_id: string): Promise<void> {
		await this.orderedListHttpDataSource.deleteById(_id)
	}
}