import { computed } from '@vue/composition-api'
import { useStore } from './useStore'


export const useAuth = () => {
	const store = useStore()

	const logged = computed(() => store.state.logged)

	const autologin = () => store.dispatch('autologin')

	return { logged, autologin }
}
