<template>
<v-container fluid class="pa-0">
	<p class="title">{{ property.title }}</p>

	<v-row justify="space-between" no-gutters>
		<v-col>
			<div>
				<v-icon color="icon">mdi-home</v-icon>
				<span class="subtitle-2">{{ formatNumber(property.buildingSurface || 0) }} m²</span>
				<span class="caption font-italic" v-if="property.buildingSurface"> ({{ buildingPricePerSqMeter(property) }} €/m²)</span>
			</div>
			<div>
				<v-icon color="icon">mdi-tree</v-icon>
				<span class="subtitle-2">{{ formatNumber(property.landSurface) }} m²</span>
				<span class="caption font-italic"> ({{ landPricePerSqMeter(property) }} €/m²)</span>
				<span class="d-block caption font-italic" v-if="property.buildableSurface">
					dont {{ formatNumber(property.buildableSurface) }} m² constructibles
				</span>
			</div>
		</v-col>
		<v-col>
			<div class="title text-right">{{ formatNumber(property.price) }} €</div>
			<div class="body-2 text-right">
				<a v-if="!!property.app" :href="property.appRef" target="_blank">{{ property.app }}</a>
			</div>
		</v-col>
	</v-row>
	
	<v-row>
		<v-col>
			<v-icon color="icon">{{ property.actualPosition ? 'mdi-map-marker-check' : 'mdi-map-marker' }}</v-icon>
			<span class="subtitle-2 text-decoration-underline active-text" @click="$emit('location:open')">{{ property.cityName }}</span>
			<span class="caption font-italic"> {{ property.zip }}, {{ property.departmentName }}</span>
			<span class="ml-4" v-if="property.actualPosition">
				<v-icon small @click="$emit('location:share')">mdi-open-in-new</v-icon>
			</span>
		</v-col>
	</v-row>
</v-container>
</template>


<script>
import { formatNumber, formatDate, landPricePerSqMeter, buildingPricePerSqMeter } from '@/formatters';

export default {
	props: {
		property: Object,
	},
	methods: {
		formatNumber,
		formatDate,
		landPricePerSqMeter,
		buildingPricePerSqMeter,
	},
}
</script>


<style lang="less" scoped>
.active-text { cursor: pointer }
</style>