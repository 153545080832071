import { computed, ComputedRef, inject, InjectionKey, provide, ref, watch } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { useTheme } from '@/composables/useTheme'


interface IMainLayout {
	isFixedViewVisible: ComputedRef<boolean>
	isFixedViewFullScreen: ComputedRef<boolean>
	isScrollViewVisible: ComputedRef<boolean>
	isAppBarViewVisible: ComputedRef<boolean>
	showFixedView: () => void
	showScrollView: () => void
	toggleFixedViewFullscreen: () => void
}

const enum RouteName {
	Home = 'home',
	Overview = 'overview',
}


const key: InjectionKey<IMainLayout> = Symbol('main-layout')

export const provideMainLayout = () => {
	const route = useRoute()
	const { vuetify } = useTheme()

	const isFixedViewRequested = ref(true)
	const isFixedViewFullScreenRequested = ref(false)

	const isDesktop = computed(() => vuetify.breakpoint.mdAndUp)
	const isRouteHome = computed(() => route.name === RouteName.Home)
	const isRouteOverview = computed(() => route.name === RouteName.Overview)

	const isFixedViewFullScreen = computed(() => isFixedViewFullScreenRequested.value)
	const isFixedViewVisible = computed(() => {
		// on desktop, always visible
		if (isDesktop.value) { return true }
		
		// on mobile, only visible when requested on the Home route
		return isRouteHome.value && isFixedViewRequested.value
	})

	const isScrollViewVisible = computed(() => {
		// on all devices, always visible on the Overview route
		if (isRouteOverview.value) { return true }

		// on desktop, visible if the fixed view is not in fullscreen mode
		if (isDesktop.value) { return !isFixedViewFullScreenRequested.value }

		// on mobile, visible if requested
		return !isFixedViewRequested.value
	})

	const isAppBarViewVisible = isRouteHome

	const showFixedView  = () => { isFixedViewRequested.value = true }
	const showScrollView = () => { isFixedViewRequested.value = false }

	const toggleFixedViewFullscreen = (forcedValue?: boolean) => {
		isFixedViewFullScreenRequested.value = forcedValue !== undefined
			? !!forcedValue
			: !isFixedViewFullScreenRequested.value
	}

	provide(key, {
		isFixedViewVisible,
		isFixedViewFullScreen,
		isScrollViewVisible,
		isAppBarViewVisible,
		showFixedView,
		showScrollView,
		toggleFixedViewFullscreen,
	})
}

export const useMainLayout = () => inject(key)!