'use strict';


const getAppleMapsURLForPlace = ({ lat, lng }) => `http://maps.apple.com/?ll=${lat},${lng}`;
const getAppleMapsURLForNavigation = ({ lat, lng }) => `http://maps.apple.com/?daddr=${lat},${lng}`;
const getGoogleMapsURLForPlace = ({ lat, lng }) => `https://maps.google.com/?q=${lat},${lng}`;
const getGoogleMapsURLForNavigation = ({ lat, lng }) => `https://maps.google.com/?daddr=${lat},${lng}`;
const getWazeURLForNavigation = ({ lat, lng }) => `https://www.waze.com/ul?ll=${lat},${lng}&navigate=yes`;

export const ExternalApp = {
	AppleMapsPlace: 'AppleMapsPlace',
	AppleMapsNav: 'AppleMapsNav',
	GoogleMapsPlace: 'GoogleMapsPlace',
	GoogleMapsNav: 'GoogleMapsNav',
	Waze: 'Waze',
};

const getURL = (app, position) => {
	switch(app) {
		case ExternalApp.AppleMapsPlace: return getAppleMapsURLForPlace(position);
		case ExternalApp.AppleMapsNav: return getAppleMapsURLForNavigation(position);
		case ExternalApp.GoogleMapsPlace: return getGoogleMapsURLForPlace(position);
		case ExternalApp.GoogleMapsNav: return getGoogleMapsURLForNavigation(position);
		case ExternalApp.Waze: return getWazeURLForNavigation(position);

		default: throw new Error(`Unsupported external app ${app}`);
	}
};

export const openLocationInApp = (app, position) => {
	window.open(encodeURI(getURL(app, position)), '_blank');
};
