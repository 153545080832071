import { inject, InjectionKey, provide } from '@vue/composition-api'


// TODO: decide which one to keep : useSingleton or makeProvidable ?

export const useSingleton = <T>(factory: () => T) => {
	let instance: T
	return (): T => {
		if (instance) { return instance }
		return (instance = factory())
	}
}

export const makeProvidable = <T>(factory: () => T) => {
	const key: InjectionKey<T> = Symbol()
	return {
		provide: () => provide(key, factory()),
		inject: () => inject(key)!,
	}
}
