import { sortAZ } from '@/helpers'


// export const setSomething = (state, something) => { state.something = something };


export const updateUIBreakpoints = (state, breakpoint) => { state.breakpoint = breakpoint };

//=================================================================================================================

export const setLoggedIn = (state, logged) => { state.logged = logged };
export const setLoginError = (state, message) => { state.loginError = message };

//=================================================================================================================

export const setUser = (state, user) => { state.user = user };

//=================================================================================================================

export const toggleMenu = (state, visible) => {
	if (visible === undefined) { visible = !state.isMenuVisible }
	state.isMenuVisible = visible;
};

//=================================================================================================================

export const setDialogVisible = (state, visible) => {
	state.dialog.visible = visible;
};

//=================================================================================================================

export const setDialogBusy = (state, busy) => {
	state.dialog.busy = busy;
};

//=================================================================================================================

export const setDialogError = (state, message) => {
	state.dialog.error = message;
};

//=================================================================================================================

export const prepareNewDraft = (state) => {
	state.dialog.error = null;
	state.draft = {
		'app': null,
		'appId': null,
		'appRef': null,
		'type': null,
		'title': null,
		'description': null,
		'city': {},
		'lat': null,
		'lng': null,
		'price': null,
		'landSurface': null,
		'buildingSurface': null,
		'buildableSurface': null,
		'images': [],
		'createdAt': null,
		'updatedAt': null
	};
};

//=================================================================================================================

export const mergeDraftData = (state, data) => {
	state.draft = { ...state.draft, ...data };
};

//=================================================================================================================

export const registerProperty = (state, data) => {
	state.properties.push(data);
};

//=================================================================================================================

export const registerProperties = (state, properties) => {
	state.properties = properties;
};

//=================================================================================================================

export const registerLists = (state, lists) => {
	state.lists = lists.sort(sortAZ('_id'));
};

//=================================================================================================================

export const registerList = (state, list) => {
	state.lists = [...state.lists, list].sort(sortAZ('_id'));
};

//=================================================================================================================

export const deleteList = (state, listID) => {
	state.lists = state.lists.filter(item => item._id !== listID);
};

//=================================================================================================================

export const selectPropertyGroup = (state, groupID) => {
	state.selectedGroup = groupID;
};

//=================================================================================================================

export const updateListItems = (state, { _id, items }) => {
	const list = state.lists.find(list => list._id === _id);
	list.items = items;
};

//=================================================================================================================

export const selectProperty = (state, propertyID) => {
	state.selection = propertyID;
};

//=================================================================================================================

export const updateProperty = (state, property) => {
	const properties = [...state.properties];
	const index = properties.findIndex(item => item._id === property._id);
	properties.splice(index, 1, property);

	state.properties = properties;
};

//=================================================================================================================

export const deleteProperty = (state, property) => {
	state.properties = state.properties.filter(item => item._id !== property._id);
};

//=================================================================================================================

export const setCookieDialogVisible = (state, visible) => {
	state.cookie.visible = visible;
};

//=================================================================================================================

export const prepareNewCookie = (state, cookie) => {
	state.cookie.cookie = cookie;
};

//=================================================================================================================

export const setNewListDialogVisible = (state, visible) => {
	state.newList.visible = visible;
};

//=================================================================================================================

export const prepareNewList = state => {
	state.newList.draft = { name: null };
};

//=================================================================================================================

export const setGalleryDialogVisible = (state, visible) => {
	state.gallery.visible = visible;
};	

//=================================================================================================================

export const setGalleryIndex = (state, index) => {
	state.gallery.index = index;
};		

//=================================================================================================================

export const setLocationDialogVisible = (state, visible) => {
	state.location.visible = visible;
};		
