


















import { defineComponent } from '@vue/composition-api'
import MapControlLabel from './MapControlLabel.vue'

export default defineComponent({
	components: { MapControlLabel },
	props: {
		value: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	},
})
