<template>
<v-menu offset-overflow offset-y :close-on-content-click="false">
	<template v-slot:activator="{ attrs, on }">
		<v-badge overlap offset-x="20" offset-y="20" :content="selection.length" :value="!!selection.length">
			<v-btn icon v-bind="attrs" v-on="on">
				<v-icon>mdi-tag-multiple</v-icon>
			</v-btn>
		</v-badge>
	</template>

	<v-card width="260px">
		<v-card-text class="pa-2">
			<v-combobox
				label="Rechercher un tag"
				solo flat multiple dense small-chips
				hide-selected
				hide-details
				:hide-no-data="!search"
				:search-input.sync="search"
				:items="tags"
				v-model="selection"
				@change="$emit('change', selection)">

				<template v-slot:no-data>
					<v-list-item>
						<span class="caption">Créer</span>
						<v-chip label small class="mx-2">{{ search }}</v-chip>
					</v-list-item>
				</template>

				<template v-slot:selection="{ attrs, item, parent, selected }">
					<v-chip label small v-bind="attrs" :input-value="selected">
						<span class="pr-2">{{ item }}</span>
						<v-icon small @click="parent.selectItem(item)">$delete</v-icon>
					</v-chip>
				</template>

				<template v-slot:item="{ item }">
					<v-chip color="secondary" label small>{{ item }}</v-chip>
				</template>

			</v-combobox>
		</v-card-text>
	</v-card>
</v-menu>
</template>

<script>
export default {
	props: {
		tags: { type: Array, default: () => [] },
		value: { type: Array, default: () => [] },
	},
	data() { return {
		selection: [],
		search: null,
	}},
	methods: {
		syncValue() {
			this.selection = this.value;
		},
	},
	watch: {
		value: 'syncValue',
	},
	mounted() {
		this.syncValue();
	},
}
</script>
