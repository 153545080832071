import { IUseCase } from '@/core/IUseCase'
import { Property } from './entities/Property'
import { IPropertyRepository } from './repositories/IPropertyRepository'


interface RefreshPropertyParams {
	_id: string
}

export class RefreshProperty implements IUseCase<RefreshPropertyParams, Promise<Property>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute({ _id }: RefreshPropertyParams): Promise<Property> {
		return await this.propertyRepository.refreshById(_id)
	}
}