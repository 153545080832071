import { PropertyType, SorterType, SortOrder } from '@/types'


const sorters = [
	{ type: SorterType.CreationDate, order: SortOrder.Desc, text: "Ajoutées récemment" },
	{ type: SorterType.EditionDate,  order: SortOrder.Desc, text: "Mises à jour récemment" },
	{ type: SorterType.Price,        order: SortOrder.Desc, text: "Les plus chères" },
	{ type: SorterType.Price,        order: SortOrder.Asc,  text: "Les moins chères" },
	{ type: SorterType.LandSurface,  order: SortOrder.Desc, text: "Les plus grands terrains" },
]

export default {
	// UI
	busy: false,
	breakpoint: {},
	isPhone: ['iPhone', 'iPad', 'iPod'].includes(navigator.platform),
	isMenuVisible: false,

	// dialogs
	dialog: {
		visible: false,
		busy: false,
		error: null,
	},
	cookie: {
		visible: false,
		cookie: {
			url: null,
			header: null,
		},
	},
	newList: {
		visible: false,
		draft: {
			name: null,
		},
	},
	gallery: {
		visible: false,
		index: null,
	},
	location: {
		visible: false,
	},

	// authentication
	logged: false,
	loginError: null,
	user: null,

	// data model
	lists: [],
	properties: [],
	selectedGroup: null,
	selection: null,
	draft: {},
	sorters,
	sorter: sorters[0],
	filters: {
		search: null,
		types: [PropertyType.Land, PropertyType.House],
		departments: [],
		regions: [],
		bounds: {
			enabled: true,
			value: null,
		},
	},
};