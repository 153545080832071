<template>
<v-card outlined class="pa-0" :to="{ name: 'overview', params: { group: selectedGroup, id: item._id } }">
	<property-list-item-title height="200" :title="item.title" :images="item.images" :hidden="item.hidden" />
	<div class="d-flex align-center">
		<div :class="`${dragHandle} text-center ps-2`" v-if="draggable">
			<v-icon>mdi-drag</v-icon>
		</div>
		<v-card-text class="flex-grow-1 text--primary">
			<div class="text-right float-right">
				<div class="title">{{ formatNumber(item.price) }} €</div>
				<v-rating
					dense x-small readonly
					empty-icon="mdi-star-outline"
					full-icon="mdi-star"
					half-icon="mdi-star-half-full"
					half-increments
					color="yellow darken-3"
					background-color="grey lighten-2"
					length="5"
					:value="item.rating" />
			</div>
			<div>
				<v-icon color="icon" small>mdi-home</v-icon>
				<span class="subtitle-2"> {{ formatNumber(item.buildingSurface || 0) }} m²</span>
			</div>
			<div>
				<v-icon color="icon" small>mdi-tree</v-icon>
				<span class="subtitle-2"> {{ formatNumber(item.landSurface) }} m²</span>
				<span class="caption font-italic text-no-wrap" v-if="item.buildableSurface">
					({{ formatNumber(item.buildableSurface) }} m² constr.)
				</span>
			</div>
			<div>
				<v-icon color="icon" small>mdi-map-marker</v-icon>
				<span class="subtitle-2"> {{ item.cityName }}</span>
				<span class="caption font-italic"> ({{ item.department }})</span>
			</div>
		</v-card-text>
	</div>
</v-card>
</template>


<script>
import { mapState } from 'vuex';
import { formatNumber, formatDate } from '@/formatters';
import PropertyListItemTitle from '@/application/components/PropertyListItemTitle';

export default {
	components: { PropertyListItemTitle },
	props: {
		item: Object,
		draggable: { type: Boolean, default: false },
		dragHandle: { type: String, default: 'property-item--drag-handle' }
	},
	computed: mapState(['selectedGroup']),
	methods: { formatNumber, formatDate },
}
</script>>


<style lang="less" scoped>
.image-container {
	position: relative;

	.image-title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #424242AA!important;
		word-break: normal;
		pointer-events: none;
	}
}
</style>