<template>
<v-card tile flat>
	<v-container fluid v-if="!items.length">
		<v-row dense>
			<v-col cols="12">
				<div class="body-2 text-center font-italic">Aucune annonce</div>
			</v-col>
		</v-row>
	</v-container>
	<my-filtered-list v-else-if="canSortList" />
	<my-ordered-list v-else />
</v-card>
</template>


<script>
import { mapGetters } from 'vuex';
import MyPropertyListItem from '@/application/fragments/MyPropertyListItem.vue';
import MyFilteredList from '@/application/fragments/MyFilteredList.vue';
import MyOrderedList from '@/application/fragments/MyOrderedList.vue';

export default {
	components: { MyPropertyListItem, MyFilteredList, MyOrderedList },
	computed: {
		...mapGetters(['propertyListType','filteredItems']),
		canSortList() {
			return this.propertyListType !== 'list';
		},
		items() {
			return this.filteredItems;
		},
	},
	mounted() {
		this.$store.dispatch('selectProperty', null);
	},
}
</script>>


<style lang="less">
.select-width-auto input { width: 0 !important }
</style>