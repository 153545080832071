export const enum PropertyType {
	Land = 'Land',
	House = 'House',
}

//=================================================================================================================

export const enum SorterType {
	City = 'City',
	Department = 'Department',
	Region = 'Region',
	Population = 'Population',
	Price = 'Price',
	PricePerLandSqMeter = 'PricePerLandSqMeter',
	PricePerBuildingSqMeter = 'PricePerBuildingSqMeter',
	LandSurface = 'LandSurface',
	BuildingSurface = 'BuildingSurface',
	BuildableSurface = 'BuildableSurface',
	CreationDate = 'CreationDate',
	EditionDate = 'EditionDate',
}

//=================================================================================================================

export const enum SortOrder {
	Asc =  'Asc',
	Desc = 'Desc',
}
