<template>
<v-text-field
	dense
	solo
	outlined
	flat
	hide-details
	clearable
	prepend-inner-icon="mdi-magnify"
	v-model="filters.search"
	@keydown.esc="clear">

	<template v-slot:prepend>
		<span class="mt-1 text-no-wrap">{{ items.length }} annonces</span>
	</template>

	<template v-slot:append>
		<my-filter-menu />
	</template>

	<template v-slot:append-outer>
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon small v-bind="attrs" v-on="on">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>

			<v-list dense>
				<v-list-item @click="openCreationDialog">
					<v-list-item-icon class="me-2">
						<v-icon>mdi-home-plus</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Nouvelle annonce</v-list-item-title>
				</v-list-item>
				<v-list-item @click="openNewListDialog">
					<v-list-item-icon class="me-2">
						<v-icon>mdi-playlist-plus</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Nouvelle liste</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</template>
</v-text-field>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MyFilterMenu from '@/application/fragments/MyFilterMenu.vue';

export default {
	components: { MyFilterMenu },
	computed: {
		...mapState(['filters']),
		...mapGetters({
			items: 'filteredItems',
		}),
	},
	methods: {
		...mapActions(['openCreationDialog','openNewListDialog']),
		clear() {
			this.filters.search = null;
		}
	},
}
</script>
