<template>
<v-card tile flat class="overview">
	<v-card-text class="mt-8 text-center" v-if="!property">
		<v-progress-circular indeterminate color="primary" />
	</v-card-text>
	<template v-else>
		<v-toolbar dense tile flat color="secondaryBar" class="overview-toolbar" :style="toolbarStyle">
			<v-btn icon class="me-2" @click="navback">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>

			<!-- <v-spacer  /> -->

			<tag-picker :tags="tags" :value="item.tags" @change="updateItemTags" />
			<list-picker :lists="lists" :id="item._id" @change="updatePropertyLists" />
			<v-btn icon @click="toggleItemVisibility">
				<v-icon>{{item.hidden ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
			</v-btn>
			<v-btn icon :loading="isRefreshing" @click="refresh" v-if="canRefresh">
				<v-icon>mdi-sync</v-icon>
			</v-btn>
			<v-btn icon @click="share(item.title)" v-if="canShare">
				<v-icon>mdi-share</v-icon>
			</v-btn>

			<v-divider class="mx-2" vertical inset />
			<v-btn icon @click="remove">
				<v-icon>mdi-delete</v-icon>
			</v-btn>

			<v-spacer />

			<paginator :index="propertyPagination.index" :total="propertyPagination.total" @prev="navToPrev" @next="navToNext" />
		</v-toolbar>

		<v-container fluid>
			<property-header-large :property="item" @location:open="openLocationDialog" @location:share="shareLocation" />
		
			<v-row>
				<v-col class="d-flex align-center">
					<v-chip disabled small label class="my-2 font-italic" v-if="!item.tags.length">Aucun tag</v-chip>
					<v-chip-group column v-else>
						<v-chip small label v-for="tag of item.tags" :key="tag">{{ tag }}</v-chip>
					</v-chip-group>
				</v-col>
				<v-col class="d-flex align-center flex-grow-0">
					<v-rating
						dense hover
						empty-icon="mdi-star-outline"
						full-icon="mdi-star"
						half-icon="mdi-star-half-full"
						half-increments
						color="yellow darken-3"
          				background-color="grey lighten-2"
						length="5"
						v-model="item.rating"
						@input="updateItemRating" />
				</v-col>
			</v-row>

			<v-carousel v-if="item.images.length"
				class="mt-2"
				height="220px"
				hide-delimiters
				:show-arrows="hasMultipleImages"
				show-arrows-on-hover>

				<v-carousel-item v-for="(url, i) in item.images" :key="i" :src="url" @click="openGalleryDialog(i)"/>
			</v-carousel>
		</v-container>

		<v-card-text class="paragraph pt-0">{{ item.description }}</v-card-text>
		
		<v-textarea
			class="mx-3 mt-2"
			outlined
			label="Notes"
			placeholder="Notes, remarques, commentaires..."
			v-model="item.notes"
			@change="updateItemNotes" />

		<v-card-text class="pt-0">
			<template v-if="item.createdAt">
				<span class="caption font-italic">
					Publié le {{ formatDate(item.createdAt) }}<template v-if="item.updatedAt">, modifié le {{ formatDate(item.updatedAt) }}</template>
				</span>
				<span class="mx-2">&dash;</span>
			</template>
			<span class="caption font-italic">Importé le {{ formatDate(item.importedAt) }}</span>
		</v-card-text>

		<!-- DEBUG -->
		<!-- <v-card-text>{{ propertyPagination }}</v-card-text> -->
	</template>
	<v-bottom-sheet v-model="isOpenInAppMenuVisible" v-if="item.actualPosition">
		<v-list>
			<v-subheader>Ouvrir dans</v-subheader>
			<v-list-item v-for="tile in tiles" :key="tile.title" @click="openLocation(tile.app)">
				<v-list-item-avatar>
					<v-avatar size="32px" tile>
						<img :src="require(`@/application/assets/${tile.img}`)" :alt="tile.title" />
					</v-avatar>
				</v-list-item-avatar>
				<v-list-item-title>{{ tile.title }}</v-list-item-title>
			</v-list-item>
		</v-list>
    </v-bottom-sheet>
</v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import shareable from '@/application/mixins/shareable';
import { formatNumber, formatDate } from '@/formatters';
import { ExternalApp } from '@/services/open-in-app';
import ListPicker from '@/application/components/ListPicker.vue';
import TagPicker from '@/application/components/TagPicker.vue';
import PropertyHeaderLarge from '@/application/components/PropertyHeaderLarge.vue';
import Paginator from '@/presenters/Paginator.vue';

export default {
	props: {
		group: { type: String },
		id: { type: String },
	},
	components: { ListPicker, TagPicker, PropertyHeaderLarge, Paginator },
	data() { return {
		isOpenInAppMenuVisible: false,
		isRefreshing: false,
		defaultNavApp: ExternalApp.GoogleMapsPlace,
		tiles: [
			{ app: ExternalApp.GoogleMapsPlace, title: 'Google Maps', img: 'google-maps.png' },
			{ app: ExternalApp.Waze,            title: 'Waze',        img: 'waze.png' },
			{ app: ExternalApp.AppleMapsPlace,  title: 'Apple Plans', img: 'apple-maps.png' },
		],
	}},
	mixins: [shareable],
	computed: {
		...mapState(['lists','isPhone']),
		...mapGetters({
			tags: 'tags',
			property: 'property',
			propertyID: 'propertyID',
			propertyPagination: 'propertyPagination',
		}),
		item() {
			return this.property || {}
		},
		hasMultipleImages() {
			return this.item.images.length > 1;
		},
		canRefresh() {
			return !this.item.hidden && !!this.item.appId
		},
		toolbarStyle() {
			switch(this.$vuetify.breakpoint.name) {
				case 'xs': return { 'left': '0' };
				case 'sm': return { 'left': '0' };
				case 'md': return { 'left': '50%' };
				case 'lg': return { 'left': '50%' };
				case 'xl': return { 'left': '50%' };
			}
		},
	},
	methods: {
		...mapActions({
			deleteItem: 'deleteProperty',
			refreshProperty: 'refreshProperty',
			updatePropertyTags: 'updatePropertyTags',
			updatePropertyNotes: 'updatePropertyNotes',
			updatePropertyRating: 'updatePropertyRating',
			togglePropertyVisibility: 'togglePropertyVisibility',
			toggleItemInList: 'togglePropertyInList',
			openGalleryDialog: 'openGalleryDialog',
			openLocationDialog: 'openLocationDialog',
			openLocationInApp: 'openLocationInApp',
			warn: 'warn',
		}),
		formatNumber,
		formatDate,

		navback() {
			this.$router.push({ name: 'home', params: { group: this.group } });
		},
		navToPrev() {
			this.$router.push({ name: 'overview', params: { group: this.group, id: this.propertyPagination.prevID } });
		},
		navToNext() {
			this.$router.push({ name: 'overview', params: { group: this.group, id: this.propertyPagination.nextID } });
		},
		async remove() {
			try {
				const isDeleted = await this.deleteItem(this.item);
				if (isDeleted) { this.navback() }
			}
			catch(err) {
				alert(err.details ? `${err.message}: ${err.details}` : err.message);
			}
		},
		async removeTag(tag) {
			try {
				await this.removeItemTag({ property: this.item, tag });
			}
			catch(err) {
				alert(err.details ? `${err.message}: ${err.details}` : err.message);
			}
		},
		async updatePropertyLists(listStatus) {
			const { propertyID } = this;
			await Promise.all(listStatus.map(({ listID, selected }) => {
				return this.toggleItemInList({ propertyID, listID, selected });
			}));
		},
		async updateItemTags(tags) {
			const { propertyID } = this;
			this.updatePropertyTags({ propertyID, tags });
		},
		async updateItemNotes(notes) {
			const { propertyID } = this;
			this.updatePropertyNotes({ propertyID, notes });
		},
		async updateItemRating(rating) {
			const { propertyID } = this;
			this.updatePropertyRating({ propertyID, rating });
		},
		async toggleItemVisibility() {
			const { propertyID } = this;
			const { hidden } = this.item;
			this.togglePropertyVisibility({ propertyID, hidden: !hidden });
		},
		async refresh() {
			const { propertyID } = this

			try {
				this.isRefreshing = true
				const { isExpired } = await this.refreshProperty({ propertyID })
				if (isExpired) { this.warn(`Cette annonce est expirée. Elle a été masquée automatiquement.`) }
			}
			finally {
				this.isRefreshing = false
			}
		},
		shareLocation() {
			const { actualPosition } = this.item;
			if (!actualPosition) { return }

			if (this.isPhone) {
				this.isOpenInAppMenuVisible = true;
			}
			else {
				this.openLocation(this.defaultNavApp);
			}
		},
		openLocation(app) {
			const { actualPosition } = this.item;
			if (!actualPosition) { return }
			
			this.openLocationInApp({ app, position: actualPosition });
			this.isOpenInAppMenuVisible = false;
		},
	},
}
</script>


<style lang="less" scoped>
.overview {
	position: relative;
	padding-top: 48px;
}
.overview-toolbar {
	position: fixed;
	top: 48px;
	right: 0;
	z-index: 2;
}

.paragraph {
	white-space: pre-line;
	word-break: normal;
}
</style>