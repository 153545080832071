<template>
<v-menu offset-y :close-on-content-click="false">
	<template v-slot:activator="{ attrs, on }">
		<v-btn icon v-bind="attrs" v-on="on">
			<v-icon>mdi-playlist-check</v-icon>
		</v-btn>
	</template>

	<v-list dense flat>
		<v-list-item v-if="!lists.length">
			<div class="text-center font-italic caption">Aucune liste</div>
		</v-list-item>

		<v-list-item-group multiple v-model="selectedIndices" @change="updateIndices">
			<v-list-item v-for="list in lists" :key="list._id">
				<template v-slot:default="{ active }">
					<v-list-item-action>
						<v-checkbox :input-value="active" color="primary" />
					</v-list-item-action>

					<v-list-item-content>
						<v-list-item-title>{{ list._id }}</v-list-item-title>
					</v-list-item-content>
				</template>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</v-menu>
</template>


<script>
export default {
	props: {
		id: String,
		lists: { type: Array, default: () => [] },
	},
	data() { return {
		selectedIndices: [],
	}},
	methods: {
		syncListIndices() {
			this.selectedIndices = this.lists
				.map(({ items }, i) => items.includes(this.id) ? i : null)
				.filter(index => index !== null);
		},
		updateIndices(newIndices) {
			const listStatus = this.lists.map((list, i) => ({ listID: list._id, selected: newIndices.includes(i) }))
			this.$emit('change', listStatus);
		},
	},
	watch: {
		lists: 'syncListIndices',
	},
	mounted() {
		this.syncListIndices();
	}
}
</script>
