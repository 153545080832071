











import { defineComponent } from '@vue/composition-api'
import MapControlLabel from './MapControlLabel.vue'

export default defineComponent({
	props: ['value'],
	components: { MapControlLabel },
})
