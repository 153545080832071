<template>
<v-list-item selectable color="primary" :to="to">
	<v-list-item-icon v-if="icon">
		<v-icon>{{ icon }}</v-icon>
	</v-list-item-icon>

	<v-list-item-title>
		<slot />
	</v-list-item-title>
	
	<v-chip x-small color="secondary" class="me-3 px-3" v-if="!isNaN(count)">{{ count }}</v-chip>
	
	<v-btn x-small icon @click.prevent="$emit('close')" v-if="closeable">
		<v-icon x-small>mdi-close</v-icon>
	</v-btn>
</v-list-item>
</template>


<script>
export default {
	props: {
		to: [String, Object],
		icon: String,
		count: Number,
		closeable: Boolean,
	},
}
</script>