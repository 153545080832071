import { IUseCase } from '@/core/IUseCase'
import { IOrderedListRepository } from './repositories/IOrderedListRepository'

interface UpdateOrderedListItemsParams {
	_id: string
	items: string[]
}

export class UpdateOrderedListItems implements IUseCase<UpdateOrderedListItemsParams, Promise<void>> {

	constructor(private readonly orderedListRepository: IOrderedListRepository) {}

	public async execute({ _id, items }: UpdateOrderedListItemsParams): Promise<void> {
		await this.orderedListRepository.updateItemsById(_id, items)
	}
}