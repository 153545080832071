import bbox from '@/utils/bbox';


const DATA_CODE = 'a2020_t4';
const LAYER_DETAILS_CODE = 'immeuble_terrestre'
const LAYER_AREA_PREFIX = 'immeuble_debit_terrestre'

export const ArcepInternet = {
	name: 'Arcep.Internet',
	label: 'Connexion Internet',
	icon: 'mdi-wifi',
	type: 'overlay',
	visible: false,
	url: '/proxy/arcep/geoserver/{path}?{params}',
	options: {
		path({ x, y, z, ...data }) {
			return z >= 17 ? `${DATA_CODE}/wms` : 'gwc/service/wmts';
		},
		params({ x, y, z, ...data }) {
			if (z >= 17) {
				// for TMS services, use -y instead of y
				const boundingBox = bbox({ x, y: data['-y'], z });
				return `service=WMS&request=GetMap&layers=${LAYER_DETAILS_CODE}&format=image/png&transparent=true&version=1.1.1&width=256&height=256&srs=EPSG:3857&bbox=${boundingBox}`
			}

			let layer;
			if      (z >= 14) { layer = `${DATA_CODE}:${LAYER_AREA_PREFIX}_aggregation_0_com` }
			else if (z >= 10) { layer = `${DATA_CODE}:${LAYER_AREA_PREFIX}_aggregation_7500_com` }
			else if (z >= 8)  { layer = `${DATA_CODE}:${LAYER_AREA_PREFIX}_aggregation_15000_dept` }
			else              { layer = `${DATA_CODE}:${LAYER_AREA_PREFIX}_aggregation_15000_reg` }

			return `request=GetTile&layer=${layer}&style=&service=WMTS&vequest=GetTile&format=image/png&version=1.1.0&tilematrixset=ARCEP-EPSG:3857&tilematrix=EPSG:3857:${z}&tileCol=${x}&tileRow=${y}`
		},
		opacity: 0.75,
		minZoom: 6,
	},
}