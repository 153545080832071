import { OrderedList } from '@/domain/entities/OrderedList'
import { HttpClient } from '../services/HttpClient'


export class OrderedListHttpDataSource {

	constructor(private readonly httpClient: HttpClient) {}


	async fetchAll(): Promise<OrderedList[]> {
		return this.httpClient.fetch<OrderedList[]>('/api/lists')
	}
	
	async create(list: OrderedList): Promise<OrderedList> {
		return this.httpClient.fetch<OrderedList>(`/api/lists`, {
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(list),
		})
	}
	
	async update({ _id, ...data }: OrderedList): Promise<void> {
		return this.httpClient.fetch<void>(`/api/lists/${_id}`, {
			method: 'put',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(data),
		})
	}
	
	async deleteById(_id: string): Promise<void> {
		return this.httpClient.fetch<void>(`/api/lists/${_id}`, { method: 'delete' })
	}
}