'use strict';


export const searchCity = async (query, { limit = 7 } = {}) => {
	const url = `https://geo.api.gouv.fr/communes?nom=${query}&boost=population&limit=${limit}`;
	const response = await fetch(encodeURI(url));
	const results = await response.json();
	return results.map(item => ({
		code: item.code,
		name: item.nom,
		zip:  item.codesPostaux[0],
		zips: item.codesPostaux,
	}));
};
