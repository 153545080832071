import { LatLng } from 'leaflet'
import { exists, fullTextSearch, hidden, sorterValueExtractor, unique, visible } from '@/helpers'
import { SortOrder } from '@/types'

export const username = state => state.user && state.user._id

//=================================================================================================================

export const mapVisible = state => !!state.breakpoint.smAndUp;

//=================================================================================================================

export const departments = state => {
	const depts = state.properties.map(item => ({ code: item.department, name: item.departmentName }));
	const deptsByID = depts.reduce((map, item) => ({ ...map, [item.code]: item }), {});
	return Object.values(deptsByID);
};

//=================================================================================================================

export const regions = state => {
	const regions = state.properties.map(item => ({ code: item.region, name: item.regionName }));
	const regionsByID = regions.reduce((map, item) => ({ ...map, [item.code]: item }), {});
	return Object.values(regionsByID);
};

//=================================================================================================================

export const lists = state => {
	return state.lists.map(list => list._id);
};

//=================================================================================================================

export const tags = state => {
	return state.properties
		.flatMap(property => property.tags)
		.filter(unique)
		.sort();
};

//=================================================================================================================

export const hasSelection = state => !!state.selection;

//=================================================================================================================

export const hasHiddenFilters = ({ filters }, { mapVisible }) => {
	const { bounds, types, departments, regions } = filters;
	return (mapVisible && bounds.enabled) || types.length < 2 || departments.length || regions.length;
};

//=================================================================================================================

export const selectedList = ({ selectedGroup }) => {
	const parts = /^list:(.+)$/.exec(selectedGroup);
	return parts && parts[1];
};

//=================================================================================================================

export const selectedTag = ({ selectedGroup }) => {
	const parts = /^tag:(.+)$/.exec(selectedGroup);
	return parts && parts[1];
};

//=================================================================================================================

export const propertyListType = (state, { selectedList, selectedTag }) => {
	if (selectedTag) { return 'tag' }
	if (selectedList) { return 'list' }
	return null;
};

//=================================================================================================================

export const hiddenProperties = ({ properties }) => {
	return properties.filter(hidden);
};

//=================================================================================================================

export const visibleProperties = ({ properties }) => {
	return properties.filter(visible);
};

//=================================================================================================================

export const propertyList = ({ lists, selectedGroup }, { visibleProperties, hiddenProperties, selectedList, selectedTag, propertyByID }) => {
	if (selectedGroup === 'hidden') { return hiddenProperties }

	if (selectedTag) {
		return visibleProperties
			.filter(property => property.tags.includes(selectedTag));
	}

	if (selectedList) {
		const list = lists.find(item => item._id === selectedList);
		if (!list) { return [] }

		return list.items
			.map(propertyByID)
			.filter(exists);
	}

	return visibleProperties;
};

//=================================================================================================================

export const filteredItemsExceptMapBounds = ({ filters, sorter }, { propertyListType, propertyList }) => {
	if (propertyListType === 'list') { return propertyList }

	const { types, departments, regions, search } = filters;
	const textSearch = fullTextSearch(search);

	// filter items
	const filtered = propertyList.filter(item => {
		// filter by type
		if (types.indexOf(item.type) === -1) { return false }

		// filter by department
		if (departments.length && departments.indexOf(item.department) === -1) { return false }

		// filter by region
		if (regions.length && regions.indexOf(item.region) === -1) { return false }

		// filter by search query
		if (textSearch && !textSearch(item)) { return false }

		return true;
	});

	// sort items
	const getSortedValue = sorterValueExtractor(sorter.type);
	filtered.sort((a,b) => {
		const valueA = getSortedValue(a);
		const valueB = getSortedValue(b);
		if (valueA < valueB) { return -1 }
		if (valueA > valueB) { return +1 }
		return 0;
	});
	if (sorter.order === SortOrder.Desc) { filtered.reverse() }

	return filtered;
};

//=================================================================================================================

export const filteredItems = ({ filters, sorter }, { propertyListType, filteredItemsExceptMapBounds }) => {
	if (propertyListType === 'list') { return filteredItemsExceptMapBounds }

	const { bounds } = filters;

	// filter items
	const filtered = filteredItemsExceptMapBounds.filter(item => {
		// filter by map bounds
		if (mapVisible && bounds.enabled && bounds.value) {
			const position = new LatLng(item.lat, item.lng);
			return bounds.value.contains(position);
		}

		return true;
	});

	return filtered;
};

//=================================================================================================================

export const listByID = state => id => id && state.lists.find(list => list._id === id) || null;

//=================================================================================================================

export const propertyByID = state => id => id && state.properties.find(property => property._id === id) || null;

//=================================================================================================================

export const propertyID = state => state.selection;

//=================================================================================================================

export const property = (state, getters) => getters.propertyByID(getters.propertyID);

//=================================================================================================================

export const propertyPagination = (state, { propertyList, property }) => {
	const index = propertyList.indexOf(property)
	const total = propertyList.length
	const prevID = index > 0 ? propertyList[index - 1]._id : null
	const nextID = index < total - 1 ? propertyList[index + 1]._id : null

	return { index, total, prevID, nextID }
};
