import { IUseCase } from '@/core/IUseCase'
import { IUserRepository } from './repositories/IUserRepository'


export class Logout implements IUseCase<void, void> {

	constructor(private readonly userRepository: IUserRepository) {}

	public execute(): void {
		this.userRepository.logout()
	}
}