import { IUseCase } from '@/core/IUseCase'
import { Cookie } from './entities/Cookie'
import { ICookieRepository } from './repositories/ICookieRepository'


export class ExportCookie implements IUseCase<Cookie, Promise<void>> {

	constructor(private readonly cookieRepository: ICookieRepository) {}

	public async execute(cookie: Cookie): Promise<void> {
		await this.cookieRepository.exportCookie(cookie)
	}
}