export class HttpClient {

	private token: string | null = null


	setAuthorization(token: string | null) {
		this.token = token
	}

	async fetch<T extends any>(url: string, options?: RequestInit): Promise<T> {
		const requestOptions = this.injectTokenIntoRequestoptions(options)
		const response = await fetch(url, requestOptions)
		return await this.handleResponse<T>(response)
	}

	//=================================================================================================================

	private injectTokenIntoRequestoptions(options?: RequestInit): RequestInit {
		if (!this.token) { return options ?? {} }
		return {
			...options,
			headers: {
				'authorization': `Bearer ${this.token}`,
				...options?.headers,
			}
		}
	}

	private async handleResponse<T>(response: Response): Promise<T> {
		if (response.ok) {
			if (response.status === 204) { return undefined as unknown as T }
			return response.json()
		}
		else {
			const message = await this.extractErrorMessage(response)
			throw new Error(message)
		}
	}
	
	private async extractErrorMessage (response: Response): Promise<string> {
		try {
			const body = await response.json()
			return body.message
		}
		catch(err) {
			return response.statusText
		}
	}
}