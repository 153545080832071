










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	props: {
		icon: { type: String, required: true },
		title: { type: String, required: true },
		active: { type: Boolean, default: false },
	},
})
