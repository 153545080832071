<template>
<v-dialog v-model="visible" persistent width="600" scrollable style="z-index:99990" :fullscreen="$vuetify.breakpoint.xs">
	<v-card>
		<v-card-title class="primary white--text">
			Nouvelle annonce
			<v-spacer />
			<span class="body-1 font-italic">{{draft.app}}</span>
		</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
				<v-container fluid class="pa-0">
					<v-row justify="center">
						<v-col cols="12" class="mt-4 pb-0">
							<!-- App -->
							<v-text-field
								outlined
								dense
								autofocus
								autocomplete="off"
								label="URL"
								v-model="draft.appRef"
								:loading="checking"
								:readonly="isAppRefValid"
								:error="!!checkError"
								:messages="checkError || []">

								<v-icon v-if="draft.appRef" slot="append" :color="appRefIconColor">{{appRefIcon}}</v-icon>

								<v-btn icon slot="append-outer" class="mt-n1" @click="pasteURL" v-if="isSupported">
									<v-icon>mdi-content-paste</v-icon>
								</v-btn>
							</v-text-field>
						</v-col>

						<!-- Property type -->
						<v-col cols="12" class="pt-0 pb-2">
							<v-radio-group
								row
								hide-details
								v-model="draft.type"
								:rules="rules.required">

								<v-radio v-for="(v, k) in types" :key="k" :label="v" :value="k" />
							</v-radio-group>
						</v-col>

						<!-- Property location -->
						<v-col cols="12" sm="6" class="py-2">
							<v-menu
								:close-on-content-click="false"
								transition="scale-transition"
        						offset-y
								v-model="cities.visible">

								<template v-slot:activator="{ on }">
									<v-text-field
										autocomplete="off"
										outlined
										hide-details
										dense
										append-icon="mdi-magnify"
										:loading="cities.loading"
										label="Ville"
										:value="draft.city.name"
										:rules="rules.requiredCity"
										v-on="on"
										@input="suggestCities"
										@focus.stop="suggestCities(draft.city.name)">
									</v-text-field>
								</template>

								<v-list dense class="pa-0 ma-0">
									<v-list-item dense v-for="item in cities.items" :key="item.code" @click="selectCity(item)">
										<v-list-item-title>{{ item.name }}</v-list-item-title>
										<v-list-item-action-text>{{ item.zip }}</v-list-item-action-text>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>

						<!-- Property price -->
						<v-col cols="12" sm="6" class="py-2">
							<v-text-field
								autocomplete="off"
								outlined
								hide-details
								dense
								label="Prix"
								suffix="€"
								v-model="draft.price"
								v-mask="masks.price"
								:rules="rules.required" />
						</v-col>

						<!-- Property surfaces -->
						<v-col cols="12" sm="4" class="py-2">
							<v-text-field
								autocomplete="off"
								outlined
								hide-details
								dense
								label="Terrain"
								suffix="m²"
								v-model="draft.landSurface"
								v-mask="masks.surface"
								:rules="rules.required" />
						</v-col>
						<v-col cols="12" sm="4" class="py-2">
							<v-text-field
								autocomplete="off"
								outlined
								hide-details
								dense
								label="Habitation"
								suffix="m²"
								v-model="draft.buildingSurface"
								v-mask="masks.surface" />
						</v-col>
						<v-col cols="12" sm="4" class="py-2">
							<v-text-field
								autocomplete="off"
								outlined
								hide-details
								dense
								label="Constructible"
								suffix="m²"
								v-model="draft.buildableSurface"
								v-mask="masks.surface" />
						</v-col>

						<!-- Property description -->
						<v-col cols="12" class="mt-6 py-2">
							<v-text-field
								autocomplete="off"
								outlined
								hide-details
								dense
								label="Titre de l'annonce"
								v-model="draft.title"
								:rules="rules.required" />
						</v-col>
						<v-col cols="12" class="py-2">
							<v-textarea
								autocomplete="off"
								outlined
								hide-details
								dense
								label="Description"
								v-model="draft.description"
								:rules="rules.required" />
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-card-text>
		<v-divider />
		<v-card-actions>
			<v-spacer />
			<v-btn text @click="closeCreationDialog">Annuler</v-btn>
			<v-btn color="primary" text @click="save">Enregistrer</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>


<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mapState, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import * as geo from '@/services/geo-api'
import { useStore } from '@/composables/useStore'
import { useClipboard } from '@/application/composables/useClipboard'

export default defineComponent({
	directives: { mask },
	setup() {
		const store = useStore()

		// template refs
		const form = ref()

		// computed variables from state
		const visible = computed(() => store.state.dialog.visible)
		const checking = computed(() => store.state.dialog.busy)
		const checkError = computed(() => store.state.dialog.error)
		const draft = computed(() => store.state.draft)

		// internal variables
		const types = ref({ 'House': 'Maison', 'Land': 'Terrain' })
		const typeIndex = ref(null)
		const cities = reactive({
			loading: false,
			visible: false,
			items: [],
		})
		const valid = ref(true)
		const masks = {
			zip: '#####',
			price: '#######',
			surface: '#######',
		}
		const rules = {
			required: [value => { return !!value || 'obligatoire' }],
			requiredCity: [
				value => { return !!draft.value.city.code || 'obligatoire' },
				value => { return value === draft.value.city.name || 'incohérent' },
			],
		}

		// alias for nested properties
		const url = computed(() => draft.value.appRef)

		// computed properties
		const isAppRefValid = computed(() => { return draft.value.appRef && draft.value.appId && !checking.value })
		const appRefIcon = computed(() =>      { return isAppRefValid.value ? 'mdi-check' : 'mdi-close' })
		const appRefIconColor = computed(() => { return isAppRefValid.value ? 'success' : 'error' })

		// actions from store
		const closeCreationDialog = () => store.dispatch('closeCreationDialog')
		const checkAppRef = appRef => store.dispatch('checkAppRef', appRef)
		const saveDraft = () => store.dispatch('saveDraft')

		// suggestions / autocomplete
		const suggestCities = async (value) => {
			// case of no input
			if (!value) { return draft.value.city = {} }

			// otherwise, trigger the suggestions
			cities.loading = true;
			try {
				cities.items = await geo.searchCity(value, { limit: 5 });
			}
			catch(err) {
				console.log(err);
			}
			cities.loading = false;
		}
		const selectCity = (city) => {
			draft.value.city = city;
			cities.visible = false;
		}

		// form & dialog
		const resetValidation = () => {
			form.value && form.value.resetValidation()
		}
		const resetSuggestions = () => {
			Object.assign(cities, { loading: false, visible: false, items: [] })
		}
		const save = async () => {
			const isValid = form.value.validate(true)
			if (!isValid) { return }
			
			await saveDraft()
			closeCreationDialog()
		}

		const { isSupported, paste } = useClipboard()
		const pasteURL = async () => {
			draft.value.appRef = await paste()
		}

		watch(visible, () => {
			// reset dialog state when opening or closing
			resetSuggestions()
			resetValidation()
		})

		watch(url, newValue => {
			// check URL when changed
			newValue && checkAppRef(newValue)
		})

		return {
			// form management
			form,
			types,
			typeIndex,
			valid,
			masks,
			rules,
			draft,
			save,
			
			// app-ref check
			url,
			checking,
			checkError,
			isAppRefValid,
			appRefIcon,
			appRefIconColor,

			// city suggestion
			cities,
			suggestCities,
			selectCity,

			// dialog
			visible,
			closeCreationDialog,
			
			// clipboard
			isSupported,
			pasteURL,
		}
	},
})
</script>