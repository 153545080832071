<template>
<div style="position:relative;z-index:1">
	<v-toolbar dense class="subnav" flat>
		<span class="text-body-2">{{ items.length }} annonces</span>
		<v-spacer />
		<div>
			<v-select
				class="select-width-auto"
				dense
				solo flat
				single-line
				hide-details
				return-object
				:items="sorters"
				v-model="sorter">

				<template #selection="{ item }">
					<span class="text-body-2 text-no-wrap text-truncate">{{ item.text }}</span>
				</template>
			</v-select>
		</div>
	</v-toolbar>

	<v-container class="pt-0" fluid stsyle="z-index:0">
		<v-row dense align="baseline">
			<v-col cols="12" lg="6" v-for="item in items" :key="item._id">
				<my-property-list-item :item="item" />
			</v-col>
		</v-row>
	</v-container>
</div>
</template>


<script>
import MyPropertyListItem from '@/application/fragments/MyPropertyListItem.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { useStore } from '@/composables/useStore';

export default defineComponent({
	components: { MyPropertyListItem },
	setup() {
		const store = useStore()

		const sorters = computed(() => store.state.sorters)
		const sorter = computed({
			get() { return store.state.sorter },
			set(value) { store.state.sorter = value }
		})

		const items = computed(() => {
			return store.getters.propertyListType !== 'list'
				? store.getters.filteredItems
				: store.getters.propertyList
		})

		return { sorter, sorters, items }
	},
})
</script>>


<style lang="scss" scoped>
.subnav {
	position: sticky;
	top: 48px;
	z-index: 10;
}
.select-width-auto input { width: 0 !important }
</style>