<template>
<v-card v-if="canLocate">
	<v-btn small icon tile :ripple="false" :disabled="loading" :color="isLocationFound ? 'primary' : undefined" @click="locate">
		<v-progress-circular size="16" width="2" indeterminate v-if="loading" />
		<v-icon small v-else>mdi-crosshairs-gps</v-icon>
	</v-btn>
</v-card>
</template>


<script>
export default {
	data() { return {
		loading: false,
		delay: 200,
		delayHandle: null,
		isLocationFound: false,
	}},
	computed: {
		canLocate() {
			return 'geolocation' in navigator
		},
	},
	methods: {
		locate() {
			if (!this.canLocate) { return }
			
			this.showLoader()
			navigator.geolocation.getCurrentPosition(location => {
				this.isLocationFound = true
				this.fireLocation(location)
				this.hideLoader()
			}, err => {
				this.fireError(err)
				this.hideLoader()
			})
		},
		showLoader() {
			this.delayHandle = setTimeout(() => { this.loading = true }, this.delay)
		},
		hideLoader() {
			if (this.delayHandle) {
				clearTimeout(this.delayHandle)
				this.delayHandle = null
			}
			this.loading = false
		},
		fireLocation(location) {
			this.$emit('locate', location)
		},
		fireError(err) {
			this.$emit('error', err)
		},
	}
}
</script>