export default {
	data() { return {
		registration: null,
		refreshing: false,
		hasUpdate: false,
	}},
	created() {
		// listen for our custom event from the SW registration
		document.addEventListener('swUpdated', event => this.updateAvailable(event), { once: true });

		// prevent multiple refreshes
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (this.refreshing) { return }
			this.refreshing = true;

			// here the actual reload of the page occurs
			window.location.reload();
		});
	},
	methods: {
		updateAvailable(event) {
			this.registration = event.detail;
			this.hasUpdate = true;
		},
		refreshApp() {
			this.hasUpdate = false;
			if (!this.registration || !this.registration.waiting) { return }

			// tell the SW to skip the waiting phase and activate the new SW
			this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		},
	},
}