var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-overflow":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-badge',{attrs:{"overlap":"","offset-x":"20","offset-y":"20","content":_vm.selection.length,"value":!!_vm.selection.length}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tag-multiple")])],1)],1)]}}])},[_c('v-card',{attrs:{"width":"260px"}},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-combobox',{attrs:{"label":"Rechercher un tag","solo":"","flat":"","multiple":"","dense":"","small-chips":"","hide-selected":"","hide-details":"","hide-no-data":!_vm.search,"search-input":_vm.search,"items":_vm.tags},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.$emit('change', _vm.selection)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"caption"},[_vm._v("Créer")]),_c('v-chip',{staticClass:"mx-2",attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm.search))])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"label":"","small":"","input-value":selected}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("$delete")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary","label":"","small":""}},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }