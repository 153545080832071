import { HttpClient } from '@/infrastructure/services/HttpClient'

import { ExportCookieHttpDataSource } from '@/infrastructure/data-sources/ExportCookieHttpDataSource'
import { GeolocationNativeDataSource } from '@/infrastructure/data-sources/GeolocationNativeDataSource'
import { OrderedListHttpDataSource } from '@/infrastructure/data-sources/OrderedListHttpDataSource'
import { PropertyHttpDataSource } from '@/infrastructure/data-sources/PropertyHttpDataSource'
import { TokenLocalDataSource } from '@/infrastructure/data-sources/TokenLocalDataSource'
import { UserSessionHttpDataSource } from '@/infrastructure/data-sources/UserSessionHttpDataSource'

import { PropertyMapper } from '@/infrastructure/PropertyMapper'

import { CookieRepository } from '@/infrastructure/CookieRepository'
import { GeolocationRepository } from '@/infrastructure/GeolocationRepository'
import { OrderedListRepository } from '@/infrastructure/OrderedListRepository'
import { PropertyRepository } from '@/infrastructure/PropertyRepository'
import { UserRepository } from '@/infrastructure/UserRepository'

import { CreateOrderedList } from '@/domain/CreateOrderedList'
import { CreateProperty } from '@/domain/CreateProperty'
import { DeleteOrderedList } from '@/domain/DeleteOrderedList'
import { DeleteProperty } from '@/domain/DeleteProperty'
import { ExportCookie } from '@/domain/ExportCookie'
import { GetAllOrderedLists } from '@/domain/GetAllOrderedLists'
import { GetCurrentLocation } from '@/domain/GetCurrentLocation'
import { GetGeolocationSupport } from '@/domain/GetGeolocationSupport'
import { GetPropertyInfo } from '@/domain/GetPropertyInfo'
import { Login } from '@/domain/Login'
import { LoginWithCredentials } from '@/domain/LoginWithCredentials'
import { Logout } from '@/domain/Logout'
import { UpdateOrderedListItems } from '@/domain/UpdateOrderedListItems'
import { UpdatePropertyNotes } from '@/domain/UpdatePropertyNotes'
import { UpdatePropertyPosition } from '@/domain/UpdatePropertyPosition'
import { UpdatePropertyRating } from '@/domain/UpdatePropertyRating'
import { UpdatePropertyTags } from '@/domain/UpdatePropertyTags'
import { UpdatePropertyVisibility } from '@/domain/UpdatePropertyVisibility'
import { GetAllProperties } from './domain/GetAllProperties'
import { RefreshProperty } from './domain/RefreshProperty'


// services
const httpClient = new HttpClient()

// data-sources
const exportCookieHttpDataSource = new ExportCookieHttpDataSource(httpClient)
const geolocationNativeDataSource = new GeolocationNativeDataSource()
const orderedListHttpDataSource = new OrderedListHttpDataSource(httpClient)
const propertyHttpDataSource = new PropertyHttpDataSource(httpClient)
const tokenLocalDataSource = new TokenLocalDataSource()
const userSessionHttpDataSource = new UserSessionHttpDataSource(httpClient)

// repositories
const cookieRepository = new CookieRepository(exportCookieHttpDataSource)
const geolocationRepository = new GeolocationRepository(geolocationNativeDataSource)
const orderedListRepository = new OrderedListRepository(orderedListHttpDataSource)
const propertyRepository = new PropertyRepository(propertyHttpDataSource, new PropertyMapper())
const userRepository = new UserRepository(tokenLocalDataSource, userSessionHttpDataSource, httpClient)

// use-cases
export const createOrderedList = new CreateOrderedList(orderedListRepository)
export const createProperty = new CreateProperty(propertyRepository)
export const deleteOrderedList = new DeleteOrderedList(orderedListRepository)
export const deleteProperty = new DeleteProperty(propertyRepository)
export const exportCookie = new ExportCookie(cookieRepository)
export const getAllProperties = new GetAllProperties(propertyRepository)
export const getAllOrderedLists = new GetAllOrderedLists(orderedListRepository)
export const getCurrentLocation = new GetCurrentLocation(geolocationRepository)
export const getGeolocationSupport = new GetGeolocationSupport(geolocationRepository)
export const getPropertyInfo = new GetPropertyInfo(propertyRepository)
export const login = new Login(userRepository)
export const loginWithCredentials = new LoginWithCredentials(userRepository)
export const logout = new Logout(userRepository)
export const updateOrderedListItems = new UpdateOrderedListItems(orderedListRepository)
export const updatePropertyNotes = new UpdatePropertyNotes(propertyRepository)
export const updatePropertyPosition = new UpdatePropertyPosition(propertyRepository)
export const updatePropertyRating = new UpdatePropertyRating(propertyRepository)
export const updatePropertyTags = new UpdatePropertyTags(propertyRepository)
export const updatePropertyVisibility = new UpdatePropertyVisibility(propertyRepository)
export const refreshProperty = new RefreshProperty(propertyRepository)
