<template>
<v-menu top open-on-hover :close-on-content-click="false">
	<template v-slot:activator="{ on, attrs }">
		<v-card>
			<v-btn icon tile :ripple="false" v-bind="attrs" v-on="on">
				<v-icon>mdi-layers</v-icon>
			</v-btn>
		</v-card>
	</template>

	<v-card class="pa-1 pe-2">
		<v-radio-group class="ma-0 pa-0" dense hide-details :value="selectedLayer" @change="selectLayer">
			<map-control-radio v-for="layer in layers" :key="layer.name" :value="layer">{{ layer.label }}</map-control-radio>
		</v-radio-group>

		<v-divider class="my-1" v-if="isDividerVisible" />
		
		<map-control-checkbox
			v-for="overlay in overlays"
			:key="overlay.name"
			:value="overlay.visible"
			@input="toggleOverlay(overlay, $event)">{{ overlay.label }}</map-control-checkbox>
	</v-card>
</v-menu>
</template>


<script>
import MapControlRadio from './MapControlRadio.vue'
import MapControlCheckbox from './MapControlCheckbox.vue'

export default {
	props: {
		layers: { type: Array, default: () => [] },
		overlays: { type: Array, default: () => [] },
	},
	components: { MapControlRadio, MapControlCheckbox },
	computed: {
		selectedLayer() {
			return this.layers.find(layer => layer.visible)
		},
		isDividerVisible() {
			return !!this.layers.length && this.overlays.length
		},
	},
	methods: {
		selectLayer(layer) {
			this.$emit('update:layer', layer)
		},
		toggleOverlay(overlay, visible) {
			this.$emit('update:overlay', { overlay, visible })
		},
	},
}
</script>


<style lang="less">
.overlay-label {
	font-size: 0.85rem;
	font-weight: 400;
}
</style>