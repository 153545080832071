<template>
<v-card flat class="fill-height">
	<v-list-item>
		<v-list-item-content class="text-center">
			<v-img height="32" contain src="@/application/assets/logo-64.png" />
			<div class="text-uppercase mt-2">
				<span class="mr-1">Mon Projet</span>
				<span class="font-weight-light primary--text">Immobilier</span>
			</div>
		</v-list-item-content>
	</v-list-item>
	
	<v-divider />

	<v-list-item>
		<v-list-item-title>{{ username }}</v-list-item-title>
		<v-list-item-icon>
			<v-btn icon @click="logout">
				<v-icon>mdi-power</v-icon>
			</v-btn>
		</v-list-item-icon>
	</v-list-item>

	<v-list-item class="justify-center">
		<v-switch
			class="mt-0"
			dense
			hide-details
			color="grey"
			prepend-icon="mdi-weather-sunny"
			append-icon="mdi-weather-night"
			v-model="$vuetify.theme.dark" />
	</v-list-item>

	<v-divider />
	
	<v-list nav dense shaped tile class="ps-0">
		<menu-item icon="mdi-home" :count="visibleProperties.length" :to="{ name: 'home', params: { group: `all` } }">
			Toutes les annonces
		</menu-item>
		<menu-item icon="mdi-eye-off" :count="hiddenProperties.length" :to="{ name: 'home', params: { group: `hidden` } }">
			Annonces masquées
		</menu-item>

		<v-subheader>Listes</v-subheader>
		<div class="text-center font-italic caption" v-if="!lists.length">Aucune liste</div>
		<menu-item v-for="list of lists" :key="list._id"
			closeable
			icon="mdi-folder-star"
			:count="list.items.length"
			:to="{ name: 'home', params: { group: `list:${list._id}` } }"
			@close="deleteList(list._id)">

			{{ list._id }}
		</menu-item>

		<v-subheader>Tags</v-subheader>
		<div class="text-center font-italic caption" v-if="!tags.length">Aucun tag</div>
		<menu-item icon="mdi-tag" :to="{ name: 'home', params: { group: `tag:${tag}` } }" v-for="tag of tags" :key="tag">
			{{ tag }}
		</menu-item>
	</v-list>
</v-card>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MenuItem from '@/application/components/MenuItem.vue';

export default {
	name: 'MyMenu',
	components: { MenuItem },
	computed: {
		...mapState(['lists']),
		...mapGetters(['username','tags','visibleProperties','hiddenProperties']),
	},
	methods: mapActions(['logout','deleteList']),
}
</script>
