import { IUseCase } from '@/core/IUseCase'
import { User } from './entities/User'
import { IUserRepository } from './repositories/IUserRepository'


export class Login implements IUseCase<void, Promise<User>> {

	constructor(private readonly userRepository: IUserRepository) {}

	public async execute(): Promise<User> {
		return this.userRepository.autologin()
	}
}