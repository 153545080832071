









import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	inheritAttrs: false,
	props: {
		btnAttrs: { type: Object, default: () => ({}) },
		btnEvents: { type: Object, default: () => ({}) },
		small: { type: Boolean, default: false },
		icon: { type: String },
	},
})
