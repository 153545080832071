import { IUseCase } from '@/core/IUseCase'
import { IGeolocationRepository } from './repositories/IGeolocationRepository'


export class GetCurrentLocation implements IUseCase<void, Promise<GeolocationPosition>> {

	constructor(private readonly geolocationRepository: IGeolocationRepository) {}

	public async execute(): Promise<GeolocationPosition> {
		return this.geolocationRepository.getCurrentLocation()
	}
}