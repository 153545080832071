import { IGeolocationRepository } from '@/domain/repositories/IGeolocationRepository'
import { GeolocationError } from '@/domain/errors/GeolocationError'
import { GeolocationNativeDataSource } from './data-sources/GeolocationNativeDataSource'


export class GeolocationRepository implements IGeolocationRepository {

	constructor(private readonly geolocationNativeDataSource: GeolocationNativeDataSource) {}


	isGeolocationSupported(): boolean {
		return this.geolocationNativeDataSource.isSupported()
	}

	async getCurrentLocation(): Promise<GeolocationPosition> {
		try {
			return await this.geolocationNativeDataSource.getCurrentPosition()
		}
		catch(err) {
			throw new GeolocationError()
		}
	}
}