<template>
<v-container fluid>
    <v-draggable
		class="row row--dense align-baseline"
        handle=".property-item--drag-handle"
        ghost-class="item--drag-ghost"
        animation="400"
        :value="items"
        @input="updateListOrder">

		<v-col class="py-2 px-2" cols="12" lg="6" v-for="(item, i) in items" :key="item._id">
			<v-badge overlap left :content="i + 1" class="item-container" color="yellow darken-2">
				<my-property-list-item draggable :item="item" />
			</v-badge>
		</v-col>
	</v-draggable>
</v-container>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import VDraggable from 'vuedraggable';
import MyPropertyListItem from '@/application/fragments/MyPropertyListItem.vue';

export default {
	components: { VDraggable, MyPropertyListItem },
	computed: {
		...mapGetters({
			items: 'filteredItems'
		}),
	},
    methods: {
		...mapActions({
			updateListOrder: 'updateListOrder',
		}),
    },
}
</script>>


<style lang="less" scoped>
.item-container {
	display: block;

	& > span {
		display: block;
	}
}
.item--drag-ghost { opacity: 0.2 }
</style>