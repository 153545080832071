<template>
<v-menu offset-y offset-x :close-on-content-click="false" nudge-bottom="12">
	<template v-slot:activator="{ on }">
		<v-btn class="ms-1" outlined x-small :color="hasHiddenFilters ? 'primary' : undefined" v-on="on">Filtrer</v-btn>
	</template>

	<v-card flat min-width="350px">
		<v-list dense class="py-0">
			<v-list-item dense v-if="mapVisible">
				<v-checkbox hide-details dense label="Filtrer avec la carte" v-model="filters.bounds.enabled" />
			</v-list-item>
			<v-list-item dense>
				<v-checkbox dense label="Terrain" value="Land" v-model="filters.types" class="me-2" />
				<v-checkbox dense label="Maison" value="House" v-model="filters.types" />
			</v-list-item>
			<v-list-item dense>
				<v-autocomplete
					outlined
					dense
					multiple
					label="Filtrer par département"
					item-text="name"
					item-value="code"
					:items="departments"
					v-model="filters.departments">

					<template v-slot:selection="{ index }">
						<span v-if="index === 0">{{ filters.departments.length }} sélectionné{{ filters.departments.length > 1 ? 's' : '' }}</span>
					</template>
				</v-autocomplete>
			</v-list-item>
			<v-list-item dense>
				<v-autocomplete
					outlined
					dense
					chips
					small-chips
					label="Filtrer par région"
					multiple
					item-text="name"
					item-value="code"
					:items="regions"
					v-model="filters.regions">

					<template v-slot:selection="{ index }">
						<span v-if="index === 0">{{ filters.regions.length }} sélectionnée{{ filters.regions.length > 1 ? 's' : '' }}</span>
					</template>
				</v-autocomplete>
			</v-list-item>
		</v-list>
	</v-card>
</v-menu>
</template>


<script>
import { mapGetters, mapState } from 'vuex';

export default {
	computed: {
		...mapState(['filters']),
		...mapGetters(['departments','regions','hasHiddenFilters','mapVisible']),
	},
}
</script>