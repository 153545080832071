import { IUseCase } from '@/core/IUseCase'
import { IGeolocationRepository } from './repositories/IGeolocationRepository'


export class GetGeolocationSupport implements IUseCase<void, boolean> {

	constructor(private readonly geolocationRepository: IGeolocationRepository) {}

	public execute(): boolean {
		return this.geolocationRepository.isGeolocationSupported()
	}
}