


























import { defineComponent, PropType } from '@vue/composition-api'
import { Location } from 'vue-router'
import { Property } from '@/domain/entities/Property'
import { formatNumber } from '@/formatters'

export default defineComponent({
	props: {
		property: { type: Object as PropType<Property>, required: true },
		to: { type: Object as PropType<Location>, required: true },
	},
	setup() {
		return { formatNumber }
	},
})
