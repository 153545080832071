import { IUseCase } from '@/core/IUseCase'
import { Property } from './entities/Property'
import { IPropertyRepository } from './repositories/IPropertyRepository'


export class GetAllProperties implements IUseCase<void, Promise<Property[]>> {

	constructor(private readonly propertyRepository: IPropertyRepository) {}

	public async execute(): Promise<Property[]> {
		return this.propertyRepository.findAll()
	}
}