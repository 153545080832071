import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/src/locale/fr'


Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	lang: {
		locales: { fr },
		current: 'fr'
	},
	theme: {
		dark: false,
		themes: {
			light: {
				background: 'FFFFFF',
				appBar: 'EEEEEE',
				secondaryBar: 'F5F5F5',
				icon: '757575',
			},
			dark: {
				background: '1E1E1E',
				appBar: '424242',
				secondaryBar: '757575',
				icon: 'BDBDBD',
			},
		},
	},
	// options: {
	// 	customProperties: true
	// },
})
