import { onBeforeUnmount, onMounted, Ref } from '@vue/composition-api'


export const useResizeObserver = (ref: Ref<any>, handler: (entries: ResizeObserverEntry[]) => void, options?: ResizeObserverOptions) => {
	let resizeObserver: ResizeObserver

	onMounted(() => {
		resizeObserver = new ResizeObserver(handler)
		resizeObserver.observe(ref.value.$el, options)
	})

	onBeforeUnmount(() => {
		resizeObserver.disconnect()
	})
}