






















































import { defineComponent } from '@vue/composition-api'
import MapControlButton from './MapControlButton.vue'
import MapControlCheckbox from './MapControlCheckbox.vue'
import MapControlSettingsLayer from './MapControlSettingsLayer.vue'

export default defineComponent({
	components: { MapControlButton, MapControlCheckbox, MapControlSettingsLayer },
	props: {
		selectedLayer: { type: String },
		selectedOverlays: { type: Array, default: () => [] },
		layers: { type: Array },
		overlays: { type: Array },
		groupingEnabled: { type: Boolean, required: true },
	},
})
