import { ref } from '@vue/composition-api'
import { LatLngLiteral } from 'leaflet'
import { useSingleton } from './utils'
import { OpenStreetMapDefault } from '@/tile-providers'
import { MapSettings } from '../MapSettings'


export const useMapState = useSingleton(() => {
	const zoom = ref<number>(6)
	const center = ref<LatLngLiteral>({ lat: 46.1977480950838, lng: 2.1663399226963524 })
	const settings = ref<MapSettings>({
		isGroupingEnabled: false,
		layer: OpenStreetMapDefault.name,
		overlays: [],
	})

	return {
		zoom,
		center,
		settings,
	}
})