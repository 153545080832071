import { inject, InjectionKey, provide } from '@vue/composition-api'
import { Framework as Vuetify } from 'vuetify'
// import vuetify from '@/plugins/vuetify'

interface IUseTheme {
	vuetify: Vuetify
	syncColorScheme(): void
}

const key: InjectionKey<IUseTheme> = Symbol('vuetify')

export const provideTheme = (vuetify: Vuetify) => {
	// @ts-ignore
	const updateTheme = ({ matches }: MediaQueryList | MediaQueryListEvent) => vuetify.theme.dark = matches

	const syncColorScheme = () => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		mediaQuery.addEventListener('change', updateTheme);
		updateTheme(mediaQuery);
	}

	provide(key, { vuetify, syncColorScheme })
}

export const useTheme = () => inject(key)!

// export const useTheme = () => {
// 	const updateTheme = ({ matches }: MediaQueryList | MediaQueryListEvent) => {
// 		// @ts-ignore
// 		console.log('updating theme', vuetify.theme.dark)
// 		// @ts-ignore
// 		vuetify.theme.dark = matches
// 	}

// 	const syncColorScheme = () => {
// 		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
// 		mediaQuery.addEventListener('change', updateTheme);
// 		updateTheme(mediaQuery);
// 	}

// 	return { vuetify, syncColorScheme }
// }
