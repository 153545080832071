<template>
<v-sheet class="fill-height" width="100%" height="100%" elevation="0" tile ref="container">
	<l-map
		ref="map"
		style="height:100%;width:100%"
		:max-zoom="17"
		:zoom.sync="mapZoom"
		:center.sync="mapCenter"
		:options="mapOptions"
		@update:zoom="emitZoom"
		@update:center="emitCenter"
		@update:bounds="emitBounds"
		@click="emitClick"
		@ready="emitReady">

		<!-- map controls -->
		<l-control position="topleft" class="control-flex control-flex-vertical">
			<slot name="top-left">
				<slot name="top-left-prepend" />
				<map-control-zoom :max="17" v-model="mapZoom" />
				<map-control-location @locate="setPosition" @error="showLocationError" />
				<slot name="top-left-append" />
				<map-control-settings
					:selected-layer="visibleLayer"
					:selected-overlays="visibleOverlays"
					:layers="layers"
					:overlays="overlays"
					:grouping-enabled="mapSettings.isGroupingEnabled"
					@update:grouping-enabled="toggleGrouping"
					@update:layer="setLayer"
					@update:overlay="toggleOverlays" />
			</slot>
		</l-control>
		<l-control-scale position="bottomleft" metric :imperial="false" />
		<l-control position="bottomleft" class="control-flex control-flex-vertical">
			<slot name="bottom-left">
				<slot name="bottom-left-prepend" />
				<map-control-legend v-if="isArcepLegendVisible" />
				<slot name="bottom-left-append" />
			</slot>
		</l-control>
		<l-control position="topright" class="control-flex control-flex-vertical control-flex-end">
			<slot name="top-right" />
		</l-control>
		<l-control position="bottomright" class="control-flex control-flex-vertical control-flex-end">
			<slot name="bottom-right" />
		</l-control>
		
		<!-- tile layers -->
		<l-tile-layer
			v-for="provider in providers"
			:key="provider.name"
			:name="provider.label" 
			:layer-type="provider.type"
			:visible="provider.visible"
			:url="provider.url"
			:options="provider.options" />

		<!-- custom content -->
		<slot></slot>

		<!-- markers -->
		<l-marker-cluster v-if="mapSettings.isGroupingEnabled">
			<slot name="markers"></slot>
		</l-marker-cluster>
		<slot name="markers" v-else></slot>

		<!-- current location marker -->
		<map-location-marker v-if="!!currentPosition" :position="currentPosition" />
	</l-map>
</v-sheet>
</template>


<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, toRaw, unref, watch } from '@vue/composition-api'
import { LMap, LControl, LControlScale, LTileLayer } from 'vue2-leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'
import MapControlZoom from '@/presenters/MapControlZoom.vue'
import MapControlLocation from '@/presenters/MapControlLocation.vue'
import MapControlLayers from '@/presenters/MapControlLayers.vue'
import MapControlLegend from '@/presenters/MapControlLegend.vue'
import MapLocationMarker from '@/presenters/MapLocationMarker.vue'
import MapControlSettings from '@/presenters/MapControlSettings.vue'
import * as tileProviders from '../tile-providers'
import { useResizeObserver } from '@/composables/useResizeObserver'

export default defineComponent({
	props: {
		zoom: { type: Number },
		center: { type: Object },
		settings: { type: Object },
	},
	components: {
		LMap,
		LControl,
		LControlScale,
		LTileLayer,
		LMarkerCluster,
		MapControlZoom,
		MapControlLocation,
		MapLocationMarker,
		MapControlLayers,
		MapControlLegend,
		MapControlSettings,
	},
	setup(props, { emit }) {
		const mapZoom = ref(props.zoom)
		const mapCenter = ref(props.center)
		const mapOptions = reactive({ zoomControl: false })

		const currentPosition = ref(null)
		const setPosition = (position) => {
			currentPosition.value = position
			mapCenter.value = [position.coords.latitude, position.coords.longitude]
		}
		const showLocationError = (err) => {
			console.error('Location error', err)
		}

		const currentLayer = ref(null)
		const layers = reactive([
			{ ...tileProviders.OpenStreetMapDefault },
			// { ...tileProviders.OpenStreetMapRegions },
			{ ...tileProviders.GeoportailSatellite },
			{ ...tileProviders.GeoportailIGN },
			{ ...tileProviders.OpenTopoMapDefault },
			{ ...tileProviders.ThunderForestTrain },
			{ ...tileProviders.ThunderForestCycle },
		])
		const overlays = reactive([
			{ ...tileProviders.GeoportailCadastre },
			{ ...tileProviders.ArcepInternet },
		])
		const visibleLayer = computed(() => currentLayer.value && currentLayer.value.name)
		const visibleOverlays = computed(() => overlays.filter(item => item.visible).map(item => item.name))
		const providers = computed(() => [...layers, ...overlays])
		const isArcepLegendVisible = computed(() => overlays[1].visible)

		// settings
		const mapSettings = reactive({ ...props.settings })
		const setLayer = layerName => {
			if (currentLayer.value) {
				if (currentLayer.value.name === layerName) { return }
				currentLayer.value.visible = false
			}

			const layer = layers.find(item => item.name === layerName)
			currentLayer.value = layer
			if (layer) { layer.visible = true }

			mapSettings.layer = layerName
		}
		const toggleOverlays = (visibleOverlayNames) => {
			overlays.forEach(overlay => overlay.visible = visibleOverlayNames.includes(overlay.name))
			mapSettings.overlays = visibleOverlayNames
		}
		const toggleGrouping = isGroupingEnabled => {
			mapSettings.isGroupingEnabled = isGroupingEnabled
		}
		watch(mapSettings, (value) => { emit('update:settings', { ...value }) })


		const map = ref()
		const container = ref()
		useResizeObserver(container, () => {
			map.value.mapObject.invalidateSize()
		})

		const emitZoom   = (value) => { emit('update:zoom',   value) }
		const emitCenter = (value) => { emit('update:center', value) }
		const emitBounds = (value) => { emit('update:bounds', value) }
		const emitClick  = ({ latlng }) => { emit('click', latlng) }
		const emitReady  = (mapObject) => { emit('ready', mapObject) }

		onBeforeMount(() => {
			setLayer(mapSettings.layer)
		})

		return {
			// refs
			container,
			map,

			// data
			mapZoom,
			mapCenter,
			mapOptions,
			mapSettings,
			currentLayer,
			currentPosition,
			layers,
			overlays,

			// computed
			providers,
			visibleLayer,
			visibleOverlays,
			isArcepLegendVisible,

			// methods
			setLayer,
			toggleOverlays,
			toggleGrouping,
			setPosition,
			showLocationError,
			emitZoom,
			emitCenter,
			emitBounds,
			emitClick,
			emitReady,
		}
	},
})
</script>


<style lang="scss">
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.control-flex {
	$controlFlexGap: 8px;

	display: flex;
	row-gap: $controlFlexGap;
	column-gap: $controlFlexGap;
	align-items: flex-start;

	&.control-flex-vertical { flex-direction: column }
	&.control-flex-center { align-items: center }
	&.control-flex-end { align-items: flex-end }
}
</style>