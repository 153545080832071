import * as useCases from '@/dependency-injection'
import * as openInApp from '@/services/open-in-app'


export const updateUIBreakpoints = ({ commit }, breakpoint) => {
	commit('updateUIBreakpoints', breakpoint)
}

//=================================================================================================================

export const autologin = async ({ commit, dispatch }) => {
	try {
		const user = await useCases.login.execute()
		commit('setLoggedIn', true)
		commit('setUser', user)
	}
	catch(err) {
		dispatch('logout')
	}
}

//=================================================================================================================

export const login = async ({ commit }, credentials) => {
	try {
		const user = await useCases.loginWithCredentials.execute(credentials)
		commit('setLoggedIn', true)
		commit('setUser', user)
	}
	catch(err) {
		commit('setLoginError', err.message)
	}
}

//=================================================================================================================

export const logout = ({ commit }) => {
	useCases.logout.execute()
	commit('selectProperty', null)
	commit('registerLists', [])
	commit('registerProperties', [])
	commit('setUser', null)
	commit('setLoggedIn', false)
	commit('toggleMenu', false)
}

//=================================================================================================================

export const toggleMenu = ({ commit }, visible) => {
	commit('toggleMenu', visible)
}

//=================================================================================================================

export const openCreationDialog = ({ commit }) => {
	commit('prepareNewDraft')
	commit('setDialogVisible', true)
}

//=================================================================================================================

export const closeCreationDialog = ({ commit }) => {
	commit('setDialogVisible', false)
}

//=================================================================================================================

export const checkAppRef = async ({ commit, dispatch }, url) => {
	commit('setDialogBusy', true)

	try {
		const data = await useCases.getPropertyInfo.execute(url)
		if (data) {
			commit('mergeDraftData', data)
			commit('setDialogError', null)
		}
		else {
			commit('setDialogError', `Annonce inconnue`)
		}
	}
	catch(err) {
		if (err.message === 'AntiBotError') {
			commit('setDialogError', err.message)
			dispatch('openCookieDialog', url)
		}
		else {
			dispatch('warn', `Une erreur est survenue: ${err.message}`)
		}
	}

	commit('setDialogBusy', false)
}

//=================================================================================================================

export const saveDraft = async ({ commit, state }) => {
	try {
		const property = await useCases.createProperty.execute(state.draft)
		commit('registerProperty', property)
	}
	catch(err) {
		console.error(err)
	}
}

//=================================================================================================================

export const fetchAll = async ({ commit }) => {
	const [properties, lists] = await Promise.all([
		useCases.getAllProperties.execute(),
		useCases.getAllOrderedLists.execute()
	])
	commit('registerProperties', properties)
	commit('registerLists', lists)
}

//=================================================================================================================

export const selectPropertyGroup = ({ commit }, listID) => {
	commit('selectPropertyGroup', listID || null)
}

//=================================================================================================================

export const selectProperty = ({ commit }, propertyID) => {
	commit('selectProperty', propertyID || null)
}

//=================================================================================================================

export const updatePropertyTags = async ({ commit, getters }, { propertyID, tags }) => {
	await useCases.updatePropertyTags.execute({ _id: propertyID, tags })

	const property = getters.propertyByID(propertyID)
	commit('updateProperty', { ...property, tags })
}

//=================================================================================================================

export const updatePropertyNotes = async ({ commit, getters }, { propertyID, notes }) => {
	await useCases.updatePropertyNotes.execute({ _id: propertyID, notes })

	const property = getters.propertyByID(propertyID)
	commit('updateProperty', { ...property, notes })
}

//=================================================================================================================

export const updatePropertyRating = async ({ commit, getters }, { propertyID, rating }) => {
	await useCases.updatePropertyRating.execute({ _id: propertyID, rating })

	const property = getters.propertyByID(propertyID)
	commit('updateProperty', { ...property, rating })
}

//=================================================================================================================

export const updatePropertyPosition = async ({ commit, getters }, { propertyID, lat, lng }) => {
	const actualPosition = { lat, lng }
	await useCases.updatePropertyPosition.execute({ _id: propertyID, actualPosition })

	const property = getters.propertyByID(propertyID)
	commit('updateProperty', { ...property, actualPosition })
}

//=================================================================================================================

export const togglePropertyVisibility = async ({ commit, getters }, { propertyID, hidden }) => {
	await useCases.updatePropertyVisibility.execute({ _id: propertyID, hidden })

	const property = getters.propertyByID(propertyID)
	commit('updateProperty', { ...property, hidden })
}

//=================================================================================================================

export const refreshProperty = async ({ commit, dispatch }, { propertyID }) => {
	try {
		const property = await useCases.refreshProperty.execute({ _id: propertyID })
		commit('updateProperty', property)
		
		return { isExpired: property.hidden }
	}
	catch(err) {
		dispatch('warn', `Une erreur est survenue: ${err.message}`)
	}
}

//=================================================================================================================

export const deleteProperty = async ({ commit, dispatch }, property) => {
	const isConfirmed = await dispatch('confirm', 'Supprimer cette annonce ?')
	if (!isConfirmed) { return false }

	await useCases.deleteProperty.execute(property._id)
	commit('deleteProperty', property)

	return true
}

//=================================================================================================================

export const createList = async ({ commit }, listName) => {
	const list = await useCases.createOrderedList.execute(listName)
	commit('registerList', list)
}

//=================================================================================================================

export const togglePropertyInList = async ({ commit, getters }, { propertyID, listID, selected }) => {
	const list = getters.listByID(listID)
	const isAlreadyInList = list.items.includes(propertyID)
	if (isAlreadyInList === selected) { return }

	const items = selected ? [...list.items, propertyID] : list.items.filter(item => item !== propertyID)
	await useCases.updateOrderedListItems.execute({ _id: listID,  items })
	
	commit('updateListItems', { _id: listID, items })
}

//=================================================================================================================

export const updateListOrder = async ({ commit, getters }, sortedItems) => {
	const listID = getters.selectedList
	const items = sortedItems.map(item => item._id)
	await useCases.updateOrderedListItems.execute({ _id: listID,  items })

	commit('updateListItems', { _id: listID, items })
}

//=================================================================================================================

export const deleteList = async ({ commit, dispatch }, listID) => {
	const isConfirmed = await dispatch('confirm', 'Supprimer cette liste ?')
	if (!isConfirmed) { return false }

	await useCases.deleteOrderedList.execute(listID)
	commit('deleteList', listID)

	return true
}

//=================================================================================================================

export const openCookieDialog = ({ commit }, url) => {
	commit('prepareNewCookie', { url })
	commit('setCookieDialogVisible', true)
}

//=================================================================================================================

export const closeCookieDialog = ({ commit }) => {
	commit('setCookieDialogVisible', false)
}

//=================================================================================================================

export const saveCookie = async ({ commit }, cookie) => {
	await useCases.exportCookie.execute(cookie)
	commit('setCookieDialogVisible', false)
}

//=================================================================================================================

export const openNewListDialog = ({ commit }) => {
	commit('prepareNewList')
	commit('setNewListDialogVisible', true)
}

//=================================================================================================================

export const closeNewListDialog = ({ commit }) => {
	commit('setNewListDialogVisible', false)
}

//=================================================================================================================

export const openGalleryDialog = ({ commit }, initialIndex) => {
	commit('setGalleryIndex', initialIndex)
	commit('setGalleryDialogVisible', true)
}

//=================================================================================================================

export const closeGalleryDialog = ({ commit }) => {
	commit('setGalleryDialogVisible', false)
}

//=================================================================================================================

export const openLocationDialog = ({ commit }) => {
	commit('setLocationDialogVisible', true)
}

//=================================================================================================================

export const closeLocationDialog = ({ commit }) => {
	commit('setLocationDialogVisible', false)
}

//=================================================================================================================

export const warn = (context, message) => {
	return window.alert(message)
}

//=================================================================================================================

export const confirm = (context, message) => {
	return window.confirm(message)
}

//=================================================================================================================

export const openLocationInApp = (context, { app, position }) => {
	openInApp.openLocationInApp(app, position)
}
