import { IUseCase } from '@/core/IUseCase'
import { OrderedList } from './entities/OrderedList'
import { IOrderedListRepository } from './repositories/IOrderedListRepository'


export class CreateOrderedList implements IUseCase<string, Promise<OrderedList>> {

	constructor(private readonly orderedListRepository: IOrderedListRepository) {}

	public async execute(name: string): Promise<OrderedList> {
		return this.orderedListRepository.create({ _id: name, items: [] })
	}
}