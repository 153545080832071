































import { computed, defineComponent } from '@vue/composition-api'
import { useMainLayout } from '@/application/composables/useMainLayout'

export default defineComponent({
	setup() {
		const { isAppBarViewVisible, isFixedViewVisible, isScrollViewVisible, showScrollView, showFixedView } = useMainLayout()

		const isToolbarDividerVisible = computed(() => isAppBarViewVisible.value && !(isScrollViewVisible.value && isFixedViewVisible.value))
		const viewClasses = computed(() => {
			const classes = ['split-view', 'fill-height']
			if (isScrollViewVisible.value && isFixedViewVisible.value) {
				classes.push('split-view--show-both')
			}
			return classes
		})

		return {
			viewClasses,
			isAppBarViewVisible,
			isFixedViewVisible,
			isScrollViewVisible,
			isToolbarDividerVisible,
			showScrollView,
			showFixedView,
		}
	},
})
