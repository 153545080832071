<template>
<v-menu offset-y>
	<template v-slot:activator="{ on, attrs }">
		<v-btn icon v-bind="attrs" v-on="on">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
	</template>

	<v-list dense>
		<v-list-item @click="openCreationDialog">
			<v-list-item-icon class="me-2">
				<v-icon>mdi-home-plus</v-icon>
			</v-list-item-icon>
			<v-list-item-title>Nouvelle annonce</v-list-item-title>
		</v-list-item>
		<v-list-item @click="openNewListDialog">
			<v-list-item-icon class="me-2">
				<v-icon>mdi-playlist-plus</v-icon>
			</v-list-item-icon>
			<v-list-item-title>Nouvelle liste</v-list-item-title>
		</v-list-item>
	</v-list>
</v-menu>
</template>


<script>
import { defineComponent } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'

export default defineComponent({
	setup() {
		const store = useStore()

		const openCreationDialog = () => { store.dispatch('openCreationDialog') }
		const openNewListDialog = () => { store.dispatch('openNewListDialog') }

		return { openCreationDialog, openNewListDialog }
	}
})
</script>
