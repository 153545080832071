import { IUseCase } from '@/core/IUseCase'
import { OrderedList } from './entities/OrderedList'
import { IOrderedListRepository } from './repositories/IOrderedListRepository'


export class GetAllOrderedLists implements IUseCase<void, Promise<OrderedList[]>> {

	constructor(private readonly orderedListRepository: IOrderedListRepository) {}

	public async execute(): Promise<OrderedList[]> {
		return this.orderedListRepository.findAll()
	}
}