<template>
<map-base :center="position" :zoom="zoom" :settings="settings" @click="updatePositionIfEditable">
	<l-marker ref="marker" :lat-lng="editedPosition" :draggable="isEditing" @update:lat-lng="updatePosition" />

	<template #top-right>
		<map-control-button small icon="mdi-close" @click="emitClose" />
	</template>

	<template #top-left-append>
		<map-control-button small icon="mdi-map-marker-radius" :disabled="isEditing" @click="isEditing = !isEditing" />
	</template>

	<template #bottom-left-append>
		<v-expand-transition>
			<v-card v-if="isEditing">
				<v-card-text class="pa-2 pb-0 text-center">
					<span class="text-body-2">{{ editedPositionText }}</span>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn small text @click="resetPosition">Annuler</v-btn>
					<v-btn small depressed color="primary" @click="commitPosition">Enregistrer</v-btn>
				</v-card-actions>
			</v-card>
		</v-expand-transition>
	</template>
</map-base>
</template>


<script>
import { defineComponent, ref, computed, toRef } from '@vue/composition-api'
import { LMarker } from 'vue2-leaflet'
import { OpenStreetMapDefault } from '@/tile-providers'
import MapBase from '@/presenters/MapBase.vue'
import MapControlLabel from '@/presenters/MapControlLabel.vue'
import MapControlButton from '@/presenters/MapControlButton.vue'

export default defineComponent({
	components: { MapBase, LMarker, MapControlLabel, MapControlButton },
	props: {
		position: { type: Object, default: () => ({ lat: 0, lng: 0 }) },
	},
	setup(props, { emit }) {
		const zoom = ref(14)
		const settings = ref({
			isGroupingEnabled: false,
			layer: OpenStreetMapDefault.name,
			overlays: [],
		})

		const isEditing = ref(false)
		const editedPosition = ref(props.position)
		const editedPositionText = computed(() => {
			const nbDecimals = 7
			const lat = Number(editedPosition.value.lat).toFixed(nbDecimals)
			const lng = Number(editedPosition.value.lng).toFixed(nbDecimals)
			const hemiNS = lat >= 0 ? 'N' : 'S'
			const hemiWE = lng >= 0 ? 'E' : 'W'
			return `${lat} ${hemiNS}, ${lng} ${hemiWE}`
		})

		const updatePosition = newPosition => {
			editedPosition.value = newPosition
		}

		const updatePositionIfEditable = clickPosition => {
			if (isEditing.value) { updatePosition(clickPosition) }
		}

		const resetPosition = () => {
			editedPosition.value = props.position
			isEditing.value = false
		}

		const commitPosition = () => {
			emit('update:position', editedPosition.value)
			isEditing.value = false
		}

		const emitClose = () => { emit('close') }

		return {
			zoom,
			settings,
			isEditing,
			editedPosition,
			editedPositionText,
			updatePosition,
			resetPosition,
			commitPosition,

			updatePositionIfEditable,
			emitClose,
		}
	},
})
</script>
