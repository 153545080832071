import { IUseCase } from '@/core/IUseCase'
import { User } from './entities/User'
import { Credentials } from './entities/value-objects/Credentials'
import { IUserRepository } from './repositories/IUserRepository'


export class LoginWithCredentials implements IUseCase<Credentials, Promise<User>> {

	constructor(private readonly userRepository: IUserRepository) {}

	public async execute(credentials: Credentials): Promise<User> {
		return this.userRepository.login(credentials)
	}
}