<template>
<v-dialog v-model="visible" persistent width="400" scrollable style="z-index:99999" :fullscreen="$vuetify.breakpoint.xs">
	<v-card>
		<v-card-title class="error white--text">
			Protection Anti-bot
		</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid" lazy-validation @submit.stop>
				<v-container fluid class="pa-0">
					<v-row justify="center">
						<v-col cols="12" class="mt-2">
							<v-text-field
								outlined
								dense
								readonly
								hide-details
								autocomplete="off"
								label="URL"
								v-model="cookie.url"
								:rules="rules.required" />
						</v-col>
						<v-col cols="12" class="mt-2">
							<v-text-field
								outlined
								dense
								hide-details
								autocomplete="off"
								label="Cookie"
								v-model="cookie.header"
								:rules="rules.required" />
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-card-text>
		<v-divider />
		<v-card-actions>
			<v-spacer />
			<v-btn text @click="closeCookieDialog">Annuler</v-btn>
			<v-btn color="primary" text @click="save">Enregistrer</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>


<script>
import { mapState, mapActions } from 'vuex';

export default {
	data() { return {
		valid: true,
		rules: {
			required: [value => { return !!value || 'obligatoire' }],
		},
	}},
	computed: {
		...mapState({
			visible: state => state.cookie.visible,
			cookie: state => state.cookie.cookie,
		}),
	},
	methods: {
		...mapActions(['closeCookieDialog','saveCookie']),

		// form & dialog
		resetValidation() {
			this.$refs.form && this.$refs.form.resetValidation();
		},
		async save() {
			const isValid = this.$refs.form.validate(true);
			if (!isValid) { return }
			
			await this.saveCookie(this.cookie);
			this.closeCookieDialog();
		},
	},
	watch: {
		visible() {
			// reset dialog state when opening or closing
			this.resetValidation();
		},
	},
}
</script>