const TOKEN_REGISTRY_KEY = 'token'

export class TokenLocalDataSource {

	private token: string | null

	constructor() {
		this.token = localStorage.getItem(TOKEN_REGISTRY_KEY)
	}

	getToken(): string | null {
		return this.token
	}

	setToken(token: string) {
		this.token = token
		localStorage.setItem(TOKEN_REGISTRY_KEY, token)
	}

	clearToken() {
		this.token = null
		localStorage.removeItem(TOKEN_REGISTRY_KEY)
	}
}