export const GeoportailCadastre = {
	name: 'Geoportail.CADASTRALPARCELS.PARCELS',
	label: 'Cadastre',
	icon: 'mdi-texture-box',
	type: 'overlay',
	visible: false,
	url: 'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER={layer}&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
	options: {
		// layer: 'CADASTRALPARCELS.PARCELS',
		layer: 'CADASTRALPARCELS.PARCELLAIRE_EXPRESS',
		apikey: 'choisirgeoportail',
		format: 'image/png',
		// style: 'bdparcellaire',
		style: 'PCI vecteur',
		minZoom: 2,
		maxZoom: 20,
		bounds: [[-75, -180], [81, 180]],
		attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
	},
}