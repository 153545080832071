<template>
<div class="image-container">
	<v-carousel :height="height" hide-delimiters :show-arrows="false">
		<v-carousel-item v-for="(url, i) in images" :key="i">
			<v-img class="white--text align-end" height="200px" :src="url" />
		</v-carousel-item>
	</v-carousel>
	<v-card-title class="py-1 mb-0 white--text image-title">{{ title }}</v-card-title>

	<v-overlay absolute :value="hidden" z-index="0">
		<v-icon>mdi-eye-off</v-icon>
	</v-overlay>
</div>
</template>


<script>
export default {
	props: {
		height: [String, Number],
		title: String,
		images: { type: Array, default: () => [] },
		hidden: { type: Boolean, default: false },
	},
}
</script>


<style lang="less" scoped>
.image-container {
	position: relative;

	.image-title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #424242AA!important;
		word-break: normal;
		pointer-events: none;
	}
}
</style>